export const MAX_SUMMARY_LENGTH = 930;

export const additionalTime = {
    min: 3 * 1000,
    mid: 7 * 1000,
    max: 20 * 1000,
};

export const getTimeRemaining = (expirationTimestamp: number | undefined) => {
    return expirationTimestamp ? Math.max(expirationTimestamp - Date.now(), 0) : 0;
};

export function getPartnerTool(partner: string) {
    switch (partner) {
        case "verizon":
            return "ACSS";

        case "att":
            return "OPUS";

        default:
            console.log(`No partner tool found for this partner: ${partner}`);
    }
}

export function getExtraSummaryTime(canAutosend: boolean, hasSummaryOnly: boolean) {
    if (canAutosend) {
        return additionalTime.min;
    } else if (hasSummaryOnly) {
        return additionalTime.mid;
    }
    return additionalTime.max;
}
