import { useQuery } from "@tanstack/react-query";
import { getProductInfo } from "../api";
import { useContentfulClient } from "../ContentfulClientProvider";
import type { Language } from "../types";

export const useProductInfo = (partner: string, productSkuArray: string[], language: Language = "en-US") => {
    const contentfulClient = useContentfulClient();
    const { data, error } = useQuery({
        queryKey: [`product-info-${productSkuArray.join(",")}=${partner}-${language}`],
        queryFn: async () => await getProductInfo(partner, contentfulClient, productSkuArray, language),
    });
    return { productInfo: data, error };
};
