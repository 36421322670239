import { CloseButton, Flex, Modal, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useTimer } from "react-timer-hook";
import { RadioCard } from "../../../common-ui";
import { useAnalytics, withPolymorphicClickAnalytics } from "../../../analytics";
import { type Answer, type Choice, type Question } from "../types";
import { CorrectAnswer, DollarSign, WrongAnswer } from "./icons";
import classes from "./MicroLearning.module.css";
import { FeedbackSection } from "./FeedbackSection";
import { postAnswer } from "./api/questionnaire";

interface MicroLearningModalProps {
    isOpen: boolean;
    close: () => void;
    question: Question;
}

export function MicroLearningModal({ isOpen, close, question }: MicroLearningModalProps): JSX.Element {
    const { dispatcher } = useAnalytics();
    const [selectedChoice, setSelectedChoice] = useState<Choice | null>(null);
    const [showFeedback, setShowFeedback] = useState(false);
    const [autoSubmit, setAutoSubmit] = useState(false);
    const [pausedAutoStart, setPausedAutoStart] = useState(false);
    const ButtonClose = withPolymorphicClickAnalytics(CloseButton, "Click");
    const selectedValueRef = useRef<Choice | null>(null);
    const handleClose = async () => {
        close();
        const answer: Answer = {
            questionId: question.questionId,
            wasAnsweredCorrectly: selectedChoice?.isCorrectAnswer ?? false,
        };
        if (selectedChoice) await postAnswer(answer);
        void dispatcher.dispatchBusinessEvent("Click_CloseMicroLearningModal", {
            questionId: question.questionId,
            question: question.question,
            answer: selectedChoice?.choice,
            wasAnsweredCorrectly: selectedChoice?.isCorrectAnswer ?? "Ignored the question",
        });
    };

    const { start: startFeedbackTimer } = useTimer({
        expiryTimestamp: new Date(Date.now() + 4000),
        autoStart: false,
        onExpire: () => setShowFeedback(true),
    });

    const { restart: restartAutoSubmitTimer, start: startAutoSubmitTimer } = useTimer({
        expiryTimestamp: new Date(Date.now() + 15000),
        autoStart: false,
        onExpire: () => setAutoSubmit(true),
    });

    const onChoiceSelected = (choice: Choice) => {
        if (selectedChoice?.choice !== choice.choice) {
            void dispatcher.dispatchBusinessEvent("Click_Choice", {
                questionId: question.questionId,
                question: question.question,
                answer: choice.choice,
                wasAnsweredCorrectly: choice.isCorrectAnswer,
            });
        }
        if (choice.isCorrectAnswer) {
            startFeedbackTimer();
            if (pausedAutoStart) restartAutoSubmitTimer(new Date(Date.now() + 15000));
            else startAutoSubmitTimer();
        }
        setPausedAutoStart(true);
        setSelectedChoice(choice);
    };

    const getClass = (isCorrectAnswer: boolean) => {
        if (isCorrectAnswer) {
            return classes.correct;
        }
        return classes.wrong;
    };

    const getColor = (isCorrectAnswer: boolean) => {
        if (isCorrectAnswer) {
            return "success.5";
        }
        return "error.5";
    };

    useEffect(() => {
        selectedValueRef.current = selectedChoice;
    }, [selectedChoice]);

    useEffect(() => {
        const handleUnmount = async () => {
            const answer: Answer = {
                questionId: question.questionId,
                wasAnsweredCorrectly: selectedValueRef.current?.isCorrectAnswer ?? false,
            };
            if (selectedValueRef.current) await postAnswer(answer);
            void dispatcher.dispatchBusinessEvent("InterruptedByCall_CloseMicroLearningModal", {
                questionId: question.questionId,
                question: question.question,
                answer: selectedChoice?.choice,
                wasAnsweredCorrectly: selectedChoice?.isCorrectAnswer,
            });
        };

        return () => {
            void handleUnmount();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (autoSubmit) {
            void handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoSubmit]);

    return (
        <Modal
            zIndex="var(--mantine-priority-highest)"
            centered
            opened={isOpen}
            onClose={() => void handleClose()}
            withCloseButton={false}
            padding={0}
            overlayProps={{
                backgroundOpacity: 0.4,
            }}
            classNames={{ header: classes.header, content: classes.content }}
        >
            <Flex className={classes.top}>
                <Flex className={classes.header} h={40}>
                    <Flex>
                        <DollarSign />
                        <Text c="primary.1" pl={6} fz={14} className={classes.title}>
                            Sales quiz
                        </Text>
                    </Flex>
                    <Flex pr="16px">
                        <ButtonClose
                            c="primary.2"
                            onClick={() => void handleClose()}
                            variant="transparent"
                            analytics={{ actionId: "CloseButton" }}
                        />
                    </Flex>
                </Flex>
                <Flex className={classes.body} h="fit-content">
                    <Flex fz={18} className={classes.question}>
                        {question.question}
                    </Flex>
                    <Flex pt="16px" gap={12} pb="16px" justify="flex-start" w="100%">
                        {question.choices.map((choice) => {
                            return (
                                <Flex key={choice.choice}>
                                    <RadioCard
                                        key={choice.choice}
                                        className={
                                            selectedChoice?.choice === choice.choice
                                                ? getClass(choice.isCorrectAnswer)
                                                : ""
                                        }
                                        iconColor="dark.5"
                                        radioColor={
                                            selectedChoice?.choice === choice.choice
                                                ? getColor(choice.isCorrectAnswer)
                                                : "dark.5"
                                        }
                                        label={choice.choice}
                                        value={choice.choice}
                                        color="transparent"
                                        p="9px 12px"
                                        selected={selectedChoice?.choice === choice.choice}
                                        onClick={() => {
                                            onChoiceSelected(choice);
                                        }}
                                        analytics={{
                                            actionId: "",
                                        }}
                                    />
                                </Flex>
                            );
                        })}
                    </Flex>
                    {selectedChoice && (
                        <Flex pb="16px">
                            <Flex>
                                {selectedChoice.isCorrectAnswer ? <CorrectAnswer /> : <WrongAnswer />}
                                <Text fw="700" pl="8px" fz={16} className={classes.answerText}>
                                    {selectedChoice.isCorrectAnswer ? "Correct!" : "Wrong answer—try again!"}
                                </Text>
                            </Flex>
                        </Flex>
                    )}
                    <Flex
                        w="100%"
                        h={selectedChoice?.isCorrectAnswer && showFeedback ? 68 : 0}
                        className={
                            selectedChoice?.isCorrectAnswer && showFeedback ? classes.feedbackDown : classes.feedbackUp
                        }
                    >
                        {selectedChoice?.isCorrectAnswer && showFeedback && (
                            <FeedbackSection
                                hideSection={() => {
                                    setShowFeedback(false);
                                }}
                                question={question.question}
                            />
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Modal>
    );
}
