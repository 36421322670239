import type { TimelineFeatures } from "@expert/features";

// TODO: Use this for feature enablement instead of app name. This is dependent on gaia changes as well.
export const featuresMap: [keyof TimelineFeatures, string][] = [
    ["isOzmoMessagingEnabled", "expert-assist-ozmo"],
    ["isReactiveCallContextEnabled", "expert-assist-call-context"],
    ["isReactiveCallContextV2Enabled", "expert-assist-call-context-v2"],
    ["isSalesFAQEnabled", "expert-assist-sales-faq"],
];

export const getAppName = ({
    isReactiveCallContextEnabled,
    isReactiveCallContextV2Enabled,
    isOzmoMessagingEnabled,
    isSalesFAQEnabled,
}: Pick<
    TimelineFeatures,
    "isOzmoMessagingEnabled" | "isReactiveCallContextEnabled" | "isReactiveCallContextV2Enabled" | "isSalesFAQEnabled"
>) => {
    if (isSalesFAQEnabled) {
        return "expert-assist-sales-faq";
    } else if (isReactiveCallContextV2Enabled) {
        return "expert-assist-call-context-v2";
    } else if (isReactiveCallContextEnabled) {
        return "expert-assist-call-context";
    } else if (isOzmoMessagingEnabled) {
        return "expert-assist-ozmo";
    }
    return "expert-assist";
};
