/* eslint-disable react/prop-types */

import { Flex } from "@mantine/core";
import { AreaChart } from "@mantine/charts";
import type { PayList, ScaleEntry } from "../types";
import { ActiveDot } from "../icons/ActiveDot";
import classes from "./SharedChartProps.module.css";
import { DynamicTooltip } from "./DynamicTooltipReplacement";

interface ChartProps {
    value: number;
    payList: PayList;
    salesDropped: number;
    toggleTooltip: React.Dispatch<React.SetStateAction<boolean>>;
    isSales: boolean;
    isMetricBroken: boolean;
}

export function SharedChart({
    value,
    payList,
    salesDropped,
    toggleTooltip,
    isSales,
    isMetricBroken,
}: ChartProps): JSX.Element | null {
    let rawValue = value - (isSales ? salesDropped : 0);
    let netValue = rawValue < 0 ? 0 : rawValue;
    if (isMetricBroken) {
        rawValue = 0;
        netValue = 0;
    }
    const maxDisplay = payList.length - 1;
    const leftSplit = payList.slice(0, netValue + 1);
    const rightSplit = payList.slice(netValue);

    if (payList.length === 0) {
        return null;
    }

    const buildDot = (props: unknown) => {
        const { cx, cy, payload } = props as {
            cx: number;
            cy: number;
            payload: { metric: number; pay: number; perMetricPay: number };
        };
        if (payload.metric === leftSplit.length - 1) {
            return <ActiveDot xOffset={cx} yOffset={cy} />;
        }
        return <svg />;
    };

    return (
        <Flex key={`${isSales}${value}`} className={classes.chartComboArea} w="100%" h="220px">
            {leftSplit.length > 1 && (
                <AreaChart
                    className={classes.leftChart}
                    areaProps={{ dot: buildDot }}
                    dotProps={{ r: 8, strokeWidth: 1, fill: "transparent" }}
                    data={leftSplit}
                    dataKey="metric"
                    fillOpacity={1}
                    series={[
                        {
                            name: "pay",
                            color: "#6700B8",
                        },
                    ]}
                    strokeWidth={3}
                    strokeDasharray="0 1"
                    w={`${String((leftSplit.length / payList.length) * 100)}%`}
                    withTooltip={false}
                    xAxisProps={{ hide: true, padding: { left: 40, right: rightSplit.length > 1 ? 0 : 40 } }}
                    yAxisProps={{
                        hide: true,
                        domain: [payList[0].pay, payList[maxDisplay].pay],
                        padding: { top: 8, bottom: 8 },
                    }}
                />
            )}
            {rightSplit.length > 1 && (
                <AreaChart
                    areaProps={{ dot: buildDot }}
                    activeDotProps={{ r: 7, strokeWidth: 0, fill: "#B67BE8" }} //for whatever reason, was not accepting the theme color
                    className={classes.rightChart}
                    classNames={{
                        axis: classes.referenceLine,
                    }}
                    data={rightSplit}
                    dataKey="metric"
                    dotProps={{ r: 8, strokeWidth: 1, fill: "transparent" }}
                    fillOpacity={0.5}
                    h="100%"
                    onMouseEnter={() => {
                        toggleTooltip(false);
                    }}
                    onMouseLeave={() => {
                        toggleTooltip(true);
                    }}
                    series={[
                        { name: "pay", color: "dark.4" },
                        { name: "pay", color: "#6700B8", strokeDasharray: "0 1" }, //pre-emptively, no theme color for this
                    ]}
                    strokeDasharray="0 1"
                    strokeWidth={3}
                    tooltipProps={{
                        content: ({ label, payload }) => {
                            const mappedLabel = label as string;
                            const mappedPayload = payload as Record<string, ScaleEntry>[];
                            return DynamicTooltip({
                                label: mappedLabel,
                                payload: mappedPayload,
                                currentValue: rawValue,
                                isSales,
                            });
                        },
                    }}
                    xAxisProps={{ hide: true, padding: { left: leftSplit.length > 1 ? 0 : 40, right: 40 } }}
                    w={`${String((rightSplit.length / payList.length) * 100)}%`}
                    yAxisProps={{
                        hide: true,
                        domain: [payList[0].pay, payList[maxDisplay].pay],
                        padding: { top: 8, bottom: 8 },
                    }}
                />
            )}
        </Flex>
    );
}
