import { useAgentStore } from "./store";
import type { AgentActivity } from "./types";

export const MAX_SUMMARY_GENERATION_DURATION = 10000; // milliseconds

export const TASK_WRAPUP_REASON = "CallEnded";
export const TASK_COMPLETE_REASON = "SessionEnded";

export const DISALLOWED_ACTIVITY_CHANGES: Partial<Record<AgentActivity, readonly AgentActivity[]>> = {
    // We should never be going from "Callback Pending" to "Available" and should always hit an intermediate activity like wrapping
    "Callback Pending": ["Available"],
};

export function canSetActivity(to: AgentActivity, from?: AgentActivity) {
    const localFrom = from ?? useAgentStore.getState().activity;

    const disallowed = DISALLOWED_ACTIVITY_CHANGES[localFrom] ?? [];

    return !disallowed.includes(to);
}
