import { Flex, Paper, Text } from "@mantine/core";
import classes from "./StaticTooltip.module.css";

interface StaticTooltipProps {
    isBroken: boolean;
    isSales: boolean;
    stats: { value: number; payout: number; penalties?: number; length: number; perUnit: number };
    show: boolean;
    leftSide: boolean;
}

export function StaticToolTip({ isSales, stats, show, leftSide, isBroken }: StaticTooltipProps) {
    if (!show) return null;
    if (isBroken) {
        return (
            <Flex className={classes.tooltipWrapper}>
                <Flex w={31} />
                <Paper px="sm" py="md" radius="sm" bg="dark.4" className={classes.tooltipSS} shadow="md" w="128px">
                    <Flex direction="column" align="center">
                        <Text fw={700} fz="12px" lh="120%" c="dark.0" mb="8px">
                            Stats unavailable📍
                        </Text>
                        <Flex className={classes.labelLine}>
                            <Text key="BrokenLabel" c="dark.0" fz="xs">
                                {`Net ${isSales ? "Sales" : "Surveys"}`}
                            </Text>
                            <Text key="BrokenValue" c="primary.3" fz="12px">
                                N/A
                            </Text>
                        </Flex>
                        <Flex className={classes.labelLine}>
                            <Text key="BrokenLabel" c="dark.0" fz="xs">
                                {`Per ${isSales ? `enroll` : `survey`}`}
                            </Text>
                            <Text key="BrokenValue" c="primary.3" fz="12px">
                                N/A
                            </Text>
                        </Flex>
                    </Flex>
                </Paper>
            </Flex>
        );
    }
    return (
        <Flex className={classes.tooltipWrapper}>
            <Flex
                w={
                    leftSide
                        ? `${String(Math.min(stats.value / stats.length, 1) * 78)}%`
                        : `${String(Math.max(stats.value / stats.length, 0) * 30 + 15)}%`
                }
            />
            {isSales ? (
                <Paper px="sm" py="md" radius="sm" bg="dark.4" className={classes.tooltipSS} shadow="md" w="128px">
                    <Flex direction="column" align="center">
                        <Text fw={700} fz="12px" lh="120%" c="dark.0" mb="8px">
                            CURRENT PAY📍
                        </Text>
                        <Flex className={classes.labelLine}>
                            <Text key="SalesLabel" c="dark.0" fz="xs">
                                Net enrolls
                            </Text>
                            <Text key="SalesValue" c="primary.3" fz="12px" className="fs-mask">
                                {stats.value}
                            </Text>
                        </Flex>
                        <Flex className={classes.labelLine}>
                            <Text key="PerSaleLabel" c="dark.0" fz="xs">
                                Per enroll
                            </Text>
                            <Text key="PerSaleValue" c="primary.3" fz="12px" className="fs-mask">
                                {`₱${stats.perUnit}`}
                            </Text>
                        </Flex>
                        <Flex className={classes.labelLine}>
                            <Text key="PenaltyLabel" c="dark.0" fz="xs">
                                Drops
                            </Text>
                            <Text key="PenaltyValue" c="error.4" fz="12px" className="fs-mask">
                                {stats.penalties}
                            </Text>
                        </Flex>
                    </Flex>
                </Paper>
            ) : (
                <Paper px="sm" py="md" radius="sm" bg="dark.4" shadow="md" w="128px">
                    <Flex direction="column" align="center" gap="1px">
                        <Text fw={700} fz="12px" lh="120%" c="dark.0" mb="8px">
                            CURRENT PAY📍
                        </Text>
                        <Flex className={classes.labelLine}>
                            <Text key="SurveysLabel" c="dark.0" fz="xs">
                                Surveys
                            </Text>
                            <Text key="SurveysValue" c="primary.3" fz="12px">
                                {stats.value}
                            </Text>
                        </Flex>
                        <Flex className={classes.labelLine}>
                            <Text key="PerSurveyLabel" c="dark.0" fz="xs">
                                Per survey
                            </Text>
                            <Text key="PerSurveyValue" c="primary.3" fz="12px">
                                {`₱${stats.value ? stats.perUnit : 0}`}
                            </Text>
                        </Flex>
                        <Flex className={classes.labelLine}>
                            <Text key="PenaltyLabel" c="dark.0" fz="xs">
                                Payout
                            </Text>
                            <Text key="PenaltyValue" c="primary.3" fz="12px">
                                {`₱${stats.payout}`}
                            </Text>
                        </Flex>
                    </Flex>
                </Paper>
            )}
        </Flex>
    );
}
