import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import dayjs from "dayjs";
import { useTimer } from "react-timer-hook";
import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import { useAgentActivity } from "../sdk";
import { useAnalytics } from "../analytics";
import { useTroubleshootingStore } from "./troubleshoot.store";

const logger = getLogger({ module: "Troubleshooting" });

export function Troubleshooting(): JSX.Element | null {
    const { setIsTroubleshooting } = useTroubleshootingStore();
    const { setNewAgentActivity } = useAgentActivity();
    const { dispatcher } = useAnalytics();
    const handleEndTroubleshooting = async () => {
        await setNewAgentActivity("Available");
        setIsTroubleshooting(false);
    };

    const { totalSeconds } = useTimer({
        expiryTimestamp: dayjs().add(20, "seconds").toDate(),
        autoStart: true,
        onExpire: () => {
            void dispatcher.dispatchBusinessEvent("TroubleshootingExpired");
            logger.info("Troubleshooting | expired");
            void handleEndTroubleshooting();
        },
    });

    useEffect(() => {
        void dispatcher.dispatchBusinessEvent("TroubleshootingOpen");
    }, [dispatcher]);

    const onClose = () => {
        void dispatcher.dispatchBusinessEvent("TroubleshootingClose");
        logger.info("Troubleshooting | close", { totalSeconds });
        setIsTroubleshooting(false);
    };

    return (
        <Modal
            opened
            centered
            onClose={onClose}
            withCloseButton={false}
            closeOnClickOutside={false}
            closeOnEscape={false}
        >
            <Stack>
                <Text size="lg" c="gray.3">
                    Call failed to connect-refresh EVL and check your audio settings
                </Text>
                <Group mt="sm">
                    <Text size="sm" c="gray.6">
                        You will be put into Available status in 20 seconds
                    </Text>
                </Group>
                <Group justify="right">
                    <Button onClick={() => void handleEndTroubleshooting()}>Go to Available ({totalSeconds})</Button>
                </Group>
            </Stack>
        </Modal>
    );
}
