import { getLogger } from "@expert/logging";
import { api } from "../../shared-utils";
import { type Partner } from "../../shared-types";
import type { ChannelShareDatabasePayload } from "./types";
import { getChannelShareData } from "./csd";

const logger = getLogger({
    module: "getCallDetails",
});

function warnIfIvr3HasDifferentData(
    callId: string,
    partner: Partner,
    data: ChannelShareDatabasePayload,
    dataFromIvr3: ChannelShareDatabasePayload,
) {
    const keys = Object.keys(data) as (keyof ChannelShareDatabasePayload)[];
    const keysWithDifferentValues = keys.filter((key) => data[key] !== dataFromIvr3[key]);
    if (keysWithDifferentValues.length === 0) return;
    logger.warn({ callId, partner, keysWithDifferentValues }, "data from IVR3 contains different values");
}

export const getCallDetails = async (callId: string, partner: Partner): Promise<ChannelShareDatabasePayload | null> => {
    if (!callId) return null;

    const channelShareDataPromise = getChannelShareData(callId, partner);

    try {
        const data = await api
            .get(`api/v1/call-details/details/${callId}`, { searchParams: { partner } })
            .json<ChannelShareDatabasePayload>();

        // forks a data-check against new, alternative API to ensure it has the same or more data
        void channelShareDataPromise.then(
            (dataFromIvr3) => dataFromIvr3 && warnIfIvr3HasDifferentData(callId, partner, data, dataFromIvr3),
        );

        return data;
    } catch (err: unknown) {
        logger.error({ err }, `getFromChannelShareDatabase API call failed with exception`);
        return null;
    }
};
