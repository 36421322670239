import type { PatternRule } from "@soluto-private/soluto-redact-pii/dist/patterns/PatternRule";

export const userPatterns: Record<string, Partial<PatternRule> | boolean> = {
    company: {
        pattern: new RegExp(
            "([A-Z&][\\w,]* )+(I[Nn][Cc](orporated)?|C[Oo](rp(oration)?)?|LLP|llc|LLC|plc|gmbh)\\.?(\\b|$)",
        ),
        flags: "g",
        replaceText: "COMPANY_NAME",
        priority: 1,
    },
    emailAddress: {
        pattern: new RegExp(
            "([a-z0-9_\\-.+]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-z0-9-]+\\.?)+))([a-z]{2,4}|[0-9]{1,3})(]?)",
        ),
        flags: "gi",
        replaceText: "EMAIL_ADDRESS",
        priority: 1,
    },
    phoneNumber: {
        pattern: new RegExp(
            "/(?:\\(?\\+?(\\d{1,3})\\)?[-.● ]*)?\\(?([0-9]{3})\\)?[-.● ]*([0-9]{3,4})[-.● ]*([0-9]{3,4})",
        ),
        flags: "g",
        replaceText: "PHONE_NUMBER",
        priority: 2,
    },
    streetAddress: {
        pattern: new RegExp(
            "(\\d+\\s*(\\w+ ){1,2}(street|st|road|rd|avenue|ave|drive|dr|loop|court|ct|circle|cir|lane|ln|boulevard|blvd|way)\\.?\\b(\\s+(apt|bldg|dept|fl|hngr|lot|pier|rm|ste|slip|trlr|unit|#)\\.? *[a-z0-9-]+\\b)?)|(P\\.? ?O\\.? *Box +\\d+)",
        ),
        flags: "gi",
        replaceText: "STREET_ADDRESS",
        priority: 1,
    },
    guid: {
        pattern: new RegExp("([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})"),
        flags: "gi",
        replaceText: "UNIQUE_IDENTIFIER",
        priority: 1,
    },
    passportNumber: {
        pattern: new RegExp("[A-PR-WY][1-9]\\d\\s?\\d{4}[1-9]"),
        flags: "gi",
        replaceText: "PASSPORT_NUMBER",
        priority: 3,
    },
    ipAddress: {
        pattern: new RegExp("(\\d{1,3}(\\.\\d{1,3}){3}|[0-9A-F]{4}(:[0-9A-F]{4}){5}(::|(:0000)+))"),
        flags: "gi",
        replaceText: "IP_ADDRESS",
        priority: 3,
    },
    creditCard: {
        pattern: new RegExp("\\d{4}[ -]?\\d{4}[ -]?\\d{4}[ -]?\\d{4}|\\d{4}[ -]?\\d{6}[ -]?\\d{4}\\d?"),
        flags: "g",
        replaceText: "CREDIT_CARD",
        priority: 4,
    },
    ssn: {
        pattern: new RegExp("\\d{3}[ -.]?\\d{2}[ -.]?\\d{4}"),
        flags: "g",
        replaceText: "SSN",
        priority: 5,
    },
    zipcode: {
        pattern: new RegExp("\\b\\d{5}\\b(-\\d{4})?\\b"),
        flags: "gi",
        replaceText: "ZIP_CODE",
        priority: 6,
    },
    digits: {
        pattern: new RegExp("(\\(?\\+?44\\)? ?)?(\\d(\\(?[-.● ]?\\d\\)?){9,11})"),
        flags: "gi",
        replaceText: "DIGIT_REDACTION",
        priority: 7,
    },
    pinCode: {
        pattern: new RegExp("\\b\\d{4}\\b"),
        flags: "gi",
        replaceText: "PIN_CODE",
        priority: 8,
    },
};
