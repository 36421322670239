import { Stack, Text } from "@mantine/core";
import { PhoneNumber } from "../../../common-ui";
import sharedStyles from "../../../common-ui/sharedStyles.module.css";
import type { VoiceTask } from "../../../sdk";

interface CallBarProps {
    task: VoiceTask;
}

function getPendingStatusText(task: VoiceTask) {
    if (task.status === "pending") return task.callDirection === "outbound" ? "Calling" : "Incoming call";
    return "Connecting";
}

export function PendingCallBar({ task }: CallBarProps): JSX.Element {
    const pendingStatusText = getPendingStatusText(task);

    return (
        <Stack gap="0">
            <Text fw={300} size="xs" className={sharedStyles.animatedEllipses}>
                {pendingStatusText}
            </Text>
            <Text className="fs-mask" fw={700} mr="sm" size="md">
                <PhoneNumber mdn={task.mdn} />
            </Text>
        </Stack>
    );
}
