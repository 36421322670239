import { Group, Stack, Text } from "@mantine/core";
import React from "react";
import { WorkspaceLoading } from "./WorkspaceLoading";
import styles from "./styles.module.css";

export interface GlobalLoaderProps {
    msg: string;
    children?: React.ReactNode;
    logo?: React.ReactNode;
}
export function GlobalLoader({ msg, children, logo }: GlobalLoaderProps) {
    return (
        <Stack justify="center" align="center" classNames={{ root: styles.globalLoaderRoot }} gap={0}>
            <WorkspaceLoading />
            {logo && <Group mb="xs">{logo}</Group>}
            <Text c="dark.3" size="md" pl="xl" pr="xl">
                {msg}
            </Text>
            {children}
        </Stack>
    );
}
