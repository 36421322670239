import { getLogger } from "@expert/logging";
import { Group, Stack, Text } from "@mantine/core";
import { useEffect } from "react";
import { useGlobalNotification } from "../../../../common-ui";
import sharedStyles from "../../../../common-ui/sharedStyles.module.css";
import { useVerifiedCustomerStore } from "../../../../customer-info";
import type { ActiveConferenceParticipant, ConferenceParticipant } from "../../../../sdk";
import { useActiveVoiceTask, useAgentSdk } from "../../../../sdk";
import { classNames } from "../../../../shared-utils";
import { HoldButton, RemoveParticipantButton } from "../../Buttons";
import styles from "./ParticipantControls.module.css";
import { ParticipantLabel } from "./ParticipantLabel";

interface ConferenceParticipantControlsProps {
    participant: ConferenceParticipant;
    isPrimary?: boolean;
}

export function ConferenceParticipantControls({ participant, isPrimary = false }: ConferenceParticipantControlsProps) {
    const logger = getLogger({ module: "participantControls" });
    const globalNotification = useGlobalNotification();
    const agentSdk = useAgentSdk();
    const task = useActiveVoiceTask();
    const { customer } = useVerifiedCustomerStore();
    const participantError = !participant.participantName;

    const customerLabel = customer?.fullName ?? "Primary caller";

    const isPending = participant.type === "pending";

    const removeParticipant = () =>
        isPending
            ? agentSdk.removePendingParticipant(task, participant.participantCallId)
            : agentSdk.removeParticipant(task, participant.participantCallId);

    useEffect(() => {
        if (participantError) {
            globalNotification.error(
                `We had trouble adding '${participant.participantCallId}' to the call.  Please check the number and try again.`,
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant.participantName]);

    if (participantError) {
        if (participant.participantCallId) {
            logger.error(
                { task },
                `Could not find participant with call ID ${participant.participantCallId}... removing from conference.`,
            );
            void removeParticipant();
        } else {
            logger.error(
                { task },
                `Could not find identifiers for the given participant... unable to remove from conference.`,
            );
        }
        return null;
    }

    const onHoldClick = (hold: boolean) =>
        agentSdk.updateParticipant(task, participant.participantName, {
            endConferenceOnExit: false,
            hold,
        });

    return (
        <Group
            classNames={{
                root: classNames(styles.participantContainer, isPrimary ? styles.primaryBackground : null),
            }}
            data-testid={isPending ? "pending-participant-panel" : "active-participant-controls"}
        >
            <Stack gap="1px">
                {isPending ? (
                    <Text size="xs" fw={300} className={sharedStyles.animatedEllipses}>
                        Calling
                    </Text>
                ) : null}
                {isPrimary ? (
                    <Group gap="0.3rem">
                        <Text size="xs" c="white">
                            {customerLabel}
                        </Text>
                    </Group>
                ) : null}
                <Group gap="xs">
                    <ParticipantLabel mdn={participant.participantName} />
                </Group>
            </Stack>
            <span className={sharedStyles.stretch} />
            <>
                {isPending ? null : (
                    <HoldButton
                        task={task}
                        outlined
                        isOnHold={!!participant.hold}
                        onPauseCallButtonClick={() => onHoldClick(true)}
                        onResumeCallButtonClick={() => onHoldClick(false)}
                        participant={participant as ActiveConferenceParticipant}
                    />
                )}
                <RemoveParticipantButton onClick={removeParticipant} disabled={!!participant.hold} />
            </>
        </Group>
    );
}
