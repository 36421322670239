import { getLogger } from "@expert/logging";
import type { AbstractAnalyticsDispatcher } from "@soluto-private/eventualize";
import { HTTPError } from "ky";
import { type Partner } from "../../shared-types";
import { getHashedValue, removeCountryCode } from "../../shared-utils";
import { type CustomerDetailsPayload } from "./types";
import { getCustomerDetailsApi } from "./api";

const logger = getLogger({
    module: "customerDetails",
    supportTeam: "sales",
});

export const getCustomerDetails = async (
    mdn: string | undefined,
    partner: Partner,
    dispatcher?: AbstractAnalyticsDispatcher,
): Promise<CustomerDetailsPayload | null> => {
    if (!mdn) return null;
    const formattedMdn = removeCountryCode(mdn);
    const jsonPayload = {
        mdn: formattedMdn,
        hashedMdn: await getHashedValue(formattedMdn),
        partner,
    };
    try {
        const client = getCustomerDetailsApi();

        const apiPath = "v1/customer/details";

        const result = await client.post(apiPath, { json: jsonPayload, retry: 0 }).json<CustomerDetailsPayload>();
        if (result.primaryAccountHolder?.mdn) {
            result.primaryAccountHolder.mdn = mdn;
        }
        void dispatcher?.dispatchBusinessEvent("CustomerDetailsStats", {
            includesPrimaryAccountHolder: !!result.primaryAccountHolder,
            includesAccountNumber: !!result.primaryAccountHolder?.accountNumber,
            includesDeviceReplacementDetails: !!result.deviceActivation,
            includesPreviousSummary: !!result.previousSummary,
            previousDeviceCount: result.previousDevices?.length ?? 0,
            techSavvyRating: result.primaryAccountHolder?.techSavvyRating ?? "Unknown",
        });
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            logger.error(`getCustomerDetails API call failed with status: ${err.response.status}`);
            return null;
        }
        logger.error({ err }, `getCustomerDetails  API call failed with exception`);
        return null;
    }
};
