import { customCleanEnv, str, url } from "envalid";

interface Env {
    accessTokenGeneratorPath: string;
    oidcSsoUrl: string;
    oidcSsoClientId: string;
    oidcSsoReturnUri: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_OIDC_TOKEN_GENERATOR_PATH: str({ default: "api/service-provider/generate-access-token/twilio" }),
        VITE_OIDC_SSO_URL: url(),
        VITE_OIDC_SSO_CLIENT_ID: str(),
        VITE_OIDC_SSO_RETURN_URI: url(),
    },
    (inputEnv): Env => ({
        // todo: move this to service-provider-specific app config
        // not all service providers need a token generator from our API
        accessTokenGeneratorPath: inputEnv.VITE_OIDC_TOKEN_GENERATOR_PATH,
        oidcSsoUrl: inputEnv.VITE_OIDC_SSO_URL,
        oidcSsoClientId: inputEnv.VITE_OIDC_SSO_CLIENT_ID,
        oidcSsoReturnUri: inputEnv.VITE_OIDC_SSO_RETURN_URI,
    }),
);
