import { Combobox, Input, InputBase, useCombobox } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { AsurionLoadingSpinner } from "@expert/common-ui";
import { useCallbacksFormStore } from "../../../callbacks.store";
import { filterTimeslotsForDay, findEquivalentTimeslot, getFormattedHourString } from "../../../utilities";
import classes from "./inputs.module.css";

//FIXME: edge case where a time will submit and fail if it was selected, waited, and that time passed before submitting
export function TimeInput() {
    const {
        unixTimeslots,
        selectedCallbackTime,
        scheduledDate,
        scheduledTimeZone,
        fetchingTimeslots,
        setSelectedCallbackTime,
    } = useCallbacksFormStore();

    /** list of available timeslots that are recalculated when selected options change */
    const filteredTimes: number[] = useMemo(
        () => filterTimeslotsForDay(scheduledDate, scheduledTimeZone, unixTimeslots),
        //? selectedCallbackTime is included in the case that the selected time has passed already
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [unixTimeslots, scheduledTimeZone, scheduledDate, selectedCallbackTime],
    );

    //? check selected time on list changes. If not available, check if an equal hour/minute is in the list and set that.
    useEffect(() => {
        if (!selectedCallbackTime) {
            //Setting a default time if none is selected
            setSelectedCallbackTime(filteredTimes[0]);
            return;
        } else if (filteredTimes.includes(selectedCallbackTime)) {
            return;
        }
        const newTime = findEquivalentTimeslot(selectedCallbackTime, filteredTimes, scheduledTimeZone);
        if (newTime) setSelectedCallbackTime(newTime);
        else setSelectedCallbackTime(filteredTimes[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredTimes]);

    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });

    const handleSubmit = (timeOption: string) => {
        setSelectedCallbackTime(Number(timeOption));
        combobox.closeDropdown();
    };

    const options = filteredTimes.map((unixTime) => (
        <Combobox.Option key={`timeslot-${unixTime}`} value={String(unixTime)}>
            {getFormattedHourString(unixTime, scheduledTimeZone)}
        </Combobox.Option>
    ));

    const renderLoading = () => <AsurionLoadingSpinner width="50%" height="50%" />;

    const renderPlaceholder = () =>
        selectedCallbackTime ? (
            getFormattedHourString(selectedCallbackTime, scheduledTimeZone)
        ) : (
            <Input.Placeholder>Time: </Input.Placeholder>
        );

    return (
        <Combobox
            classNames={{ dropdown: classes.dropdown }}
            onOptionSubmit={handleSubmit}
            store={combobox}
            zIndex="var(--mantine-priority-higher)"
        >
            <Combobox.Target>
                <InputBase
                    component="button"
                    onClick={() => combobox.toggleDropdown()}
                    pointer
                    disabled={fetchingTimeslots}
                    rightSection={<Combobox.Chevron />}
                    rightSectionPointerEvents="none"
                    size="lg"
                >
                    {fetchingTimeslots ? renderLoading() : renderPlaceholder()}
                </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Options mah={200}>{options}</Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}
