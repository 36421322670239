import { type GuideCommand } from "@soluto-private/expert-workspace-timeline";
import { type OpenGuideSource } from "@expert/solve-tools";
import { useDeviceActivationStore } from "../store";
import { type IvrInfo } from "../types";

interface UseGuideCommand {
    isActivationGuideEnabled: boolean;
    isPhase1ConversationalIVR: boolean;
    readonly openGuide: (source: OpenGuideSource) => void;
}

interface CalculationProps extends Omit<UseGuideCommand, "openGuide"> {
    ivrInfo?: IvrInfo;
}

const calculateDisabled = ({
    isActivationGuideEnabled,
    isPhase1ConversationalIVR,
    ivrInfo,
}: CalculationProps): boolean => {
    if (!isActivationGuideEnabled) return true;
    if (isPhase1ConversationalIVR && !ivrInfo) return true;
    return false;
};

const calculateTooltip = ({
    isActivationGuideEnabled,
    isPhase1ConversationalIVR,
    ivrInfo,
}: CalculationProps): string | undefined => {
    if (!isActivationGuideEnabled) return "Coming soon";
    if (isPhase1ConversationalIVR && !ivrInfo) return "There isn’t context available for this call.";
    return undefined;
};

export const useGuideCommand = ({
    isActivationGuideEnabled,
    isPhase1ConversationalIVR,
    openGuide,
    ...rest
}: UseGuideCommand): GuideCommand => {
    const { ivrInfo } = useDeviceActivationStore();
    const calculationProps = { isActivationGuideEnabled, isPhase1ConversationalIVR, ...rest, ivrInfo };

    const command: GuideCommand = {
        label: isPhase1ConversationalIVR ? "Launch call context" : "Launch device activation",
        quickActionLabel: isActivationGuideEnabled ? "/a" : undefined,
        disabled: calculateDisabled(calculationProps),
        tooltip: calculateTooltip(calculationProps),
        open: openGuide,
    };

    return command;
};
