import "./AsurionLoadingSpinner.css";

interface LoaderProps {
    width?: string | number;
    height?: string | number;
}

export function AsurionLoadingSpinner({ width = "100%", height = "100%", ...rest }: LoaderProps): JSX.Element {
    return (
        <div className="asurion-loading-spinner-container" data-aui="loader" style={{ width, height }}>
            <svg
                className="asurion-loading-spinner"
                aria-describedby="loader-title"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                {...rest}
            >
                <g id="eSOCNY03Vh62_tr" transform="translate(12,11.982764) rotate(0)">
                    <g transform="scale(0.374102,0.374102) translate(-172.009537,-224.748871)">
                        <path
                            d="M16.740383,-21.819392C23.282934,-16.792264,27.5,-8.888607,27.5,0C27.5,15.187831,15.187831,27.5,0,27.5s-27.5-12.312169-27.5-27.5c0-11.228019,6.728981-20.884399,16.374237-25.156435"
                            fill="rgba(84,131,204,0)"
                            id="eSOCNY03Vh63"
                            stroke="var(--mantine-color-dark-3)"
                            strokeDasharray="143.36"
                            strokeDashoffset="120"
                            strokeLinecap="square"
                            strokeWidth="6"
                            transform="translate(172.009536 224.748878)"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}
