import { getLogger } from "@expert/logging";
import { injectExternalScript, injectInternalScript } from "./script-injection";

declare global {
    interface Window {
        Appcues?: {
            page: () => void;
            identify: (user: string, metadata: object) => void;
            track: (eventName: string, eventAttributes: Record<string, unknown>) => void;
        };
    }
}

const logger = getLogger({
    module: "appcues",
});

interface AppcuesOptions {
    isEnabled: boolean;
    url: string;
    appName: string;
}

export const appcuesBaseAttributes: Record<string, unknown> = {};

export const initAppcues = ({ isEnabled, url, appName }: AppcuesOptions) => {
    if (isEnabled && url && appName) {
        appcuesBaseAttributes.appName = appName;
        injectInternalScript("AppcuesSettingsScript", "window.AppcuesSettings = { enableURLDetection: true };");
        injectExternalScript("AppcuesScript", url);
        logger.debug(`Appcues script injected.`);
    } else {
        logger.warn(`Did NOT inject appcues script`);
    }
};

interface AppcuesIdentifier {
    identity: string;
    name: string;
    email?: string;
    partner?: string;
}

export const setAppcuesIdentifier = ({ identity, name, email = identity, partner }: AppcuesIdentifier) => {
    if (!identity) return;
    if (partner) appcuesBaseAttributes.partner = partner;
    window.Appcues?.identify(identity, {
        name,
        email,
        partner,
    });
};

export const trackAppcuesEvent = (eventName: string, eventAttributes: Record<string, unknown>) => {
    window.Appcues?.track(eventName, { ...appcuesBaseAttributes, ...eventAttributes });
};
