import type { SVGProps } from "react";

export function CaretLeft({ height = 8, width = 12, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" {...rest}>
            <path
                fill={fill ?? "var(--mantine-color-dark-2)"}
                fillRule="evenodd"
                d="M0.0859373 6.50008L5.79304 12.2072L7.20726 10.793L2.91436 6.50008L7.20726 2.20718L5.79304 0.792969L0.0859373 6.50008Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
