import { getLogger } from "@expert/logging";

export type AnyData = {
    [key: string]: unknown;
};

const logger = getLogger({
    module: "tinyEmitter",
});

/** Defines a conditional type that will allow for an optional payload when the type of the event payload is undefined
 * And require the correctly typed payload when that payload is defined
 * Less Syntax: (TEvents[TEvent] == undefined) ? undefined : TEvents[TEvent]
 */
type EmitArgs<TEvents extends Record<TEvent, unknown>, TEvent extends keyof TEvents> = TEvents[TEvent] extends undefined
    ? [eventName: TEvent]
    : [eventName: TEvent, payload: TEvents[TEvent]];

export class TinyEmitter<TEvents extends Partial<AnyData> = AnyData> {
    private loggingEnabled = true; // TEMP till we interface with a global logger in eventualize
    private readonly eventBus: Comment;

    constructor(name: string, loggingEnabled = true) {
        this.eventBus = new Comment(name);
        this.loggingEnabled = loggingEnabled;
    }

    on<TEvent extends keyof TEvents>(eventName: TEvent, handlerFn: (payload: TEvents[TEvent]) => unknown) {
        const nativeEventHandler = (event: Event) => {
            if (!this.isCustomEvent<TEvent>(event)) {
                throw new Error("Unsupported event. Event is not a custom event");
            }

            handlerFn(event.detail);
        };

        this.eventBus.addEventListener(String(eventName), nativeEventHandler);
        return () => {
            this.eventBus.removeEventListener(String(eventName), nativeEventHandler);
        };
    }

    once<TEvent extends keyof TEvents>(
        eventName: TEvent,
        handlerFn: (payload: TEvents[TEvent]) => unknown,
        filter?: (payload: TEvents[TEvent]) => boolean,
    ) {
        const cleanup = this.on(eventName, (localPayload: TEvents[TEvent]) => {
            if (filter && !filter(localPayload)) return;

            handlerFn(localPayload);
            cleanup();
        });

        return cleanup;
    }

    emit<TEvent extends keyof TEvents>(...args: EmitArgs<TEvents, TEvent>) {
        const [eventName, payload] = args;

        const event = payload
            ? new CustomEvent<TEvents[TEvent]>(String(eventName), { detail: payload })
            : new CustomEvent(String(eventName));

        if (this.loggingEnabled) {
            logger.info(payload, `Emitting event "${String(eventName)}"`);
        }

        this.eventBus.dispatchEvent(event);
    }

    private isCustomEvent<TEvent extends keyof TEvents>(event: Event): event is CustomEvent<TEvents[TEvent]> {
        return "detail" in event;
    }
}
