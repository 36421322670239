import type { DefaultMessageKey, MessageType } from "../../shared-types";
import { useTimelineStore } from "../../state";
import { useCreateActions } from "./useCreateActions";
import type { MultipleActions, QuickAction } from "./types";
import { getQuickActions } from "./getQuickActions";

interface QuickActionsManagerProps {
    currentMessageType: MessageType;
    messageId?: string;
    defaultMessageKey?: DefaultMessageKey;
    isOverflowing: boolean;
    isExpanded: boolean;
    isLastBotMessage: boolean;
    isLastProactiveMessage: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

/*
Based on design, only a single quick actions group is showing on the screen at any time.
*/
export function useQuickActionsManager({
    currentMessageType,
    messageId,
    defaultMessageKey,
    isOverflowing,
    isExpanded,
    isLastBotMessage,
    isLastProactiveMessage,
    setIsExpanded,
}: QuickActionsManagerProps): QuickAction[] {
    const { partner } = useTimelineStore();

    const allActions = useCreateActions({ setIsExpanded, messageId, messageType: currentMessageType });
    const currentActions: MultipleActions[] = [];

    if (isLastBotMessage || isLastProactiveMessage) {
        switch (currentMessageType) {
            case "Search": {
                if (partner === "att") {
                    currentActions.push(allActions.neutralOrPositiveFeedback, allActions.tsnr);
                }
                break;
            }
            case "Proactive": {
                if (isOverflowing && !isExpanded) {
                    currentActions.push(allActions.expandMessage, allActions.negativeFeedback);
                } else {
                    currentActions.push(allActions.neutralOrPositiveFeedback, allActions.negativeFeedback);
                }
                break;
            }
            case "Default": {
                if (defaultMessageKey === "inCallDeviceInfo") {
                    currentActions.push(allActions.devices);
                }
                break;
            }
            case "SessionSummary":
                if (isOverflowing && !isExpanded) {
                    currentActions.push(allActions.expandMessage);
                }
                break;
            default:
                break;
        }
    }

    const quickActions = getQuickActions(currentActions);
    return quickActions;
}
