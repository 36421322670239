import { Flex, Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { usePartner } from "../../sdk";
import { useFeatureFlag } from "../../shared-utils";
import classes from "./PayDashboard.module.css";
import { ChartHeader, PayLabel, SharedChart, StaticToolTip } from "./components";
import { formatPayPeriod, trimScales } from "./utils";
import type { ScaleEntry } from "./types";
import { useDashboardData } from "./calls/useDashboardData";
import { XAxis } from "./components/XAxis";
import { UpdatedTime } from "./components/UpdatedTime";

export function PayDashboard(): JSX.Element | null {
    const [showTooltip, setShowTooltip] = useState(true);
    const [chartMode, setChartMode] = useState("Sales");
    const [salePayouts, setSalePayouts] = useState<ScaleEntry[]>([]);
    const [surveyPayouts, setSurveyPayouts] = useState<ScaleEntry[]>([]);
    const { data, isLoading } = useDashboardData();
    const partner = usePartner();
    const { data: isSalesWidgetsEnabled } = useFeatureFlag("Sales-isWidgetsEnabled", { partner });

    useEffect(() => {
        if (data?.sellPayScale) {
            setSalePayouts(trimScales(data.sellPayScale));
        }
        if (data?.servePayScale) {
            setSurveyPayouts(trimScales(data.servePayScale));
        }
    }, [data, chartMode]);
    if (isLoading) {
        return (
            <Flex direction="column" h={463} w={isSalesWidgetsEnabled ? "100%" : 800} justify="center" align="center">
                <Loader />
            </Flex>
        );
    }
    if (!data) {
        return (
            <Flex
                direction="column"
                h={463}
                w={isSalesWidgetsEnabled ? "100%" : 800}
                align="center"
                justify="center"
                p={16}
                fz={16}
                fw={400}
            >
                We’ve run into an issue and can’t show your data right now. Try again later.
            </Flex>
        );
    }
    const currentSales = data.enrolls;
    const salesDropped = data.churns;
    const perfectSurveys = data.surveys;
    //TODO: get region information about expert
    const region = "PH";
    //TODO: remove this disable once we're pulling region information

    const payDenomination = region === "PH" ? "₱" : "$";
    const isSales = chartMode === "Sales";

    const netSales = currentSales - salesDropped;

    const salesPay = data.sellPay;
    const surveyPay = data.servePay;
    const leftSideTooltip = isSales
        ? netSales > (salePayouts.length - 1) / 3
        : perfectSurveys > (surveyPayouts.length - 1) / 3;
    const salesMetrics = [currentSales, salesPay, data.sellRate];
    const surveyMetrics = [perfectSurveys, surveyPay, data.serveRate];

    const isSalesBroken = salesMetrics.includes(0) && salesMetrics.filter((it) => it !== 0).length > 0;
    const isSurveyBroken = surveyMetrics.includes(0) && surveyMetrics.filter((it) => it !== 0).length > 0;
    const isChartBroken = isSales ? salePayouts.length <= 1 : surveyPayouts.length <= 1;
    const isMetricBroken = isSales ? isSalesBroken : isSurveyBroken; //if one of these has both 0 and non zero values, something is broken

    return (
        <Flex
            bg="dark.8"
            direction="column"
            mt=".5rem"
            className={isSalesWidgetsEnabled ? classes.rootForWidget : classes.rootForSidebar}
        >
            {!isSalesWidgetsEnabled && (
                <Flex pb={8} pt={16} pl={16} pr={16} fz={20} fw={700}>
                    My stats
                </Flex>
            )}
            <Flex className={classes.updateTimestamp} align="center">
                <UpdatedTime updatedTime={data.lastUpdated} />
            </Flex>
            <Flex className={classes.modalHeader} h="92px">
                <PayLabel
                    salesPay={!isSalesBroken ? salesPay : -1}
                    surveyPay={!isSurveyBroken ? surveyPay : -1}
                    payDenomination={payDenomination}
                />
                <Flex fz="12px" c="dark.2" pt={24}>
                    {`Pay period: ${data.payPeriodStart ? formatPayPeriod(data.payPeriodStart) : "pay period unknown"}`}
                </Flex>
            </Flex>
            <Flex className={classes.modalBody} h="100%" direction="column" justify="space-between">
                <ChartHeader isSales={isSales} value={chartMode} setValue={setChartMode} />
                {!isChartBroken ? (
                    <>
                        <Flex className={classes.combinedArea} w="100%" h="100%">
                            <SharedChart
                                isMetricBroken={isMetricBroken}
                                value={isSales ? currentSales : perfectSurveys}
                                payList={isSales ? salePayouts : surveyPayouts}
                                salesDropped={salesDropped}
                                toggleTooltip={setShowTooltip}
                                isSales={isSales}
                            />
                            <StaticToolTip
                                isSales={isSales}
                                isBroken={isMetricBroken}
                                leftSide={leftSideTooltip}
                                stats={
                                    isSales
                                        ? {
                                              value: netSales,
                                              payout: salesPay,
                                              penalties: salesDropped,
                                              length: salePayouts.length,
                                              perUnit: data.sellRate,
                                          }
                                        : {
                                              value: perfectSurveys,
                                              payout: surveyPay,
                                              length: surveyPayouts.length,
                                              perUnit: data.serveRate,
                                          }
                                }
                                show={showTooltip}
                            />
                        </Flex>
                        <XAxis payList={isSales ? salePayouts : surveyPayouts} />
                        <Flex justify="center" className={classes.xLabel}>
                            {isSales ? "Net VHDP enrolls" : "Perfect Surveys"}
                        </Flex>
                    </>
                ) : (
                    <Flex justify="center">We’re unable to load the chart right now. Try again later.</Flex>
                )}
            </Flex>
        </Flex>
    );
}
