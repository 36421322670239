import { getLogger } from "@expert/logging";
import { Flex, Group, Modal, Text } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { useShallow } from "zustand/react/shallow";
import { ScheduleCallbackForm } from "../../call-controls";
import { useToast } from "../../common-ui";
import { isVoiceTask, useAgentSdk, useAgentStore, usePartner, useSession } from "../../sdk";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import styles from "./EditSessionSummaryModal.module.css";
import { SessionSummaryActions } from "./SessionSummaryActions";
import { SessionSummaryForm } from "./SessionSummaryForm";
import { useSummary } from "./useSummary";
import { additionalTime, getExtraSummaryTime, getPartnerTool } from "./utils";

const logger = getLogger({ module: "sessionSummary" });

export function SessionSummary(): JSX.Element | null {
    const clipboard = useClipboard({ timeout: 500 });
    const agentSdk = useAgentSdk();
    const partner = usePartner();
    const toast = useToast();

    const [setCanAppend] = useSessionSummaryStore((state) => [state.setCanAppend]);

    const [view, setView] = useState<"edit-summary" | "schedule-callback">("edit-summary");
    const [summaryLoaded, setSummaryLoaded] = useState(false);
    const [summaryGenerationTimedOut, setSummaryGenerationTimedOut] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [finalTimestamp, setFinalTimestamp] = useState<number>(Date.now());

    const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);

    const { activity, pendingActivity } = useAgentStore(useShallow((state) => state));
    const activeSession = useSession();
    const activeTask = activeSession.currentTask;
    if (activeTask && !isVoiceTask(activeTask)) {
        throw new Error("Unsupported task channel. SessionSummary should not be rendered without a voice task");
    }

    const agentCallSid =
        activeTask?.agentCallId ??
        activeSession.tasks.filter(isVoiceTask).findLast((task) => task.agentCallId !== undefined)?.agentCallId;

    const {
        isLoadingSummaryFeature,
        summaryGenerated,
        validAccountNumber,
        requiresManualCopy,
        summary,
        setSessionSummaryFromGaia,
        onSessionSummaryCompleted,
        onDismiss,
    } = useSummary();

    const shouldAutoGenerateSummary = activeTask?.locale !== "es-mx";
    const shouldSendSummary = validAccountNumber && !requiresManualCopy;
    const needsCopy = !shouldSendSummary && !isCopied;
    const sessionSummaryLoading =
        (!summaryLoaded || isLoadingSummaryFeature || !summaryGenerationTimedOut) && shouldAutoGenerateSummary;
    const canSendSuccessfulSummary = summaryGenerated && shouldSendSummary;
    const shouldShowTextArea = !sessionSummaryLoading && !isCopied && !canSendSuccessfulSummary;
    const shouldDisableTextArea = summaryGenerated && needsCopy;

    // A wrapping task or a newly wrapping agent means we are entering session summary
    const inWrappingState = activeTask?.status === "wrapping" || activity === "Wrapping";
    const taskIsActive = activeTask?.status === "assigned";
    const hasImmediateCallBack = activeSession.callbackState?.callbackType === "CallbackNow";

    const baseInitialTimer = activeSession.wrappingState?.expirationTimestamp ?? Date.now();
    const initialTimer = shouldAutoGenerateSummary ? baseInitialTimer : baseInitialTimer + additionalTime.max;
    const endSessionTimestamp = shouldAutoGenerateSummary ? finalTimestamp : initialTimer;

    async function endSession(reason: "AgentTerminatedWrapping" | "WrappingTimeExpired") {
        logger.trace({ reason, partner }, "Ending session wrapup");
        await onSessionSummaryCompleted(closeModal, summary, endSessionTimestamp, !shouldSendSummary, agentCallSid);
        await agentSdk.endWrapup(reason, partner);
    }

    async function handleWrappingExpired() {
        if (needsCopy) copySummary();
        await endSession("WrappingTimeExpired");
    }

    const copySummary = () => {
        try {
            clipboard.copy(summary);
            setIsCopied(true);
            toast.success("Session summary copied to clipboard");
        } catch (err) {
            logger.error({ err }, "Error copying session summary");
        }
    };

    async function handleCopyOrEndSessionClick() {
        if (needsCopy) copySummary();
        else await endSession("AgentTerminatedWrapping");
    }

    /** Prevent mantine's attempt to close the modal when the outside overlay is clicked */
    const interceptClose = () => null;
    const callbackScheduleHandler = () => {
        onDismiss(closeModal, agentCallSid, { checkSummary: false });
    };

    function getSummaryHeader() {
        if (summaryGenerationTimedOut) {
            if (requiresManualCopy) {
                if (!summaryGenerated) {
                    return "Oh no—we couldn’t generate a summary";
                }
                return `Add notes to ${getPartnerTool(partner)}`;
            }
            if (!validAccountNumber) {
                return `Autosend failed—add notes to ${getPartnerTool(partner)}`;
            } else if (!summaryGenerated) {
                return "Oh no—we couldn’t generate a summary";
            }
        }
        return "Wrap up";
    }

    function getSummaryButtonText() {
        if (summaryGenerationTimedOut) {
            if (needsCopy) {
                return "Copy notes";
            } else if (pendingActivity === "Offline") {
                return "End session and log off";
            } else if (!summaryGenerated && shouldSendSummary) {
                return "Save and end session";
            }
            return "End session";
        }
        return "Generating";
    }

    const { totalSeconds, restart } = useTimer({
        expiryTimestamp: new Date(initialTimer),
        autoStart: true,
        onExpire: () => {
            if (summaryGenerationTimedOut || !shouldAutoGenerateSummary) void handleWrappingExpired();
            else restartTimer();
        },
    });

    function restartTimer() {
        const timestamp = activeSession.wrappingState?.expirationTimestamp
            ? activeSession.wrappingState.expirationTimestamp +
              getExtraSummaryTime(canSendSuccessfulSummary, shouldDisableTextArea)
            : Date.now();
        setFinalTimestamp(timestamp);
        setSummaryGenerationTimedOut(true);
        setTimeout(() => {
            restart(new Date(timestamp), true);
        }, 50);
    }

    useEffect(() => setView("edit-summary"), [opened]);

    useEffect(() => {
        if (!shouldAutoGenerateSummary) {
            setSummaryLoaded(true);
            setSummaryGenerationTimedOut(true);
            return;
        }

        if (opened && inWrappingState && !isLoadingSummaryFeature) {
            void setSessionSummaryFromGaia(setSummaryLoaded, agentCallSid);
        }
    }, [
        setSessionSummaryFromGaia,
        isLoadingSummaryFeature,
        inWrappingState,
        shouldAutoGenerateSummary,
        opened,
        agentCallSid,
    ]);

    useEffect(() => {
        if (hasImmediateCallBack) return;

        if (inWrappingState) {
            openModal();
        } else if (taskIsActive) {
            setCanAppend(true);
        }
    }, [hasImmediateCallBack, inWrappingState, taskIsActive, setCanAppend, openModal]);

    return (
        <Group className={styles.modalContainer}>
            <Modal
                centered
                transitionProps={{
                    transition: "pop",
                }}
                classNames={{
                    inner: styles.modalInner,
                    overlay: styles.modalOverlay,
                    content: styles.modalContent,
                }}
                onClose={interceptClose}
                opened={opened}
                overlayProps={{
                    backgroundOpacity: 0.1,
                }}
                size="32rem"
                withCloseButton={false}
            >
                <Flex id="modal-heading" align="center" justify="space-between" mb="xl">
                    <Text className={styles.modalTitle} fw="var(--mantine-font-weight-normal)" fz="md">
                        {view === "schedule-callback" ? "Schedule Callback" : getSummaryHeader()}
                    </Text>
                </Flex>
                {view === "schedule-callback" ? (
                    <ScheduleCallbackForm shouldShowActions={false} />
                ) : (
                    <SessionSummaryForm
                        partner={partner}
                        loading={sessionSummaryLoading}
                        shouldDisableTextArea={shouldDisableTextArea}
                        shouldShowTextArea={shouldShowTextArea}
                        canSendSuccessfulSummary={canSendSuccessfulSummary}
                    />
                )}
                <SessionSummaryActions
                    onBackClick={() => setView("edit-summary")}
                    afterSubmitClickDefault={callbackScheduleHandler}
                    getButtonText={getSummaryButtonText}
                    setView={setView}
                    endSession={handleCopyOrEndSessionClick}
                    summaryUnavailable={sessionSummaryLoading || !summary}
                    shouldCopy={needsCopy}
                    expirySeconds={totalSeconds}
                    view={view}
                />
            </Modal>
        </Group>
    );
}
