import { useMemo } from "react";
import { type ContextProps } from "@expert/features";
import { type Logger, getLogger } from "@expert/logging";
import { AnalyticsProvider, useReactAnalytics } from "@soluto-private/eventualize-react";
import { type GuideCommand, Timeline } from "@soluto-private/expert-workspace-timeline";
import { type VoiceTask, useActiveTask, usePartner, useSessionId } from "../sdk";
import { userCache } from "../shared-utils";
import { env } from "../../config";
import { useSetupSubscriptions } from "./state";
import { features } from "./utils";

const logger = getLogger({
    rootModule: "PlatformTimeline",
    supportTeam: "solve",
});

function SetupSubscriptions({ loggerWithContext }: { loggerWithContext: Logger }): React.JSX.Element {
    useSetupSubscriptions(loggerWithContext);

    return <></>;
}

export function PlatformTimeline({ guide, isFocused }: { guide: GuideCommand; isFocused: boolean }): JSX.Element {
    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this

    const callSid = (task as VoiceTask)?.agentCallId;
    const expertId = userCache.employeeId ?? "";
    const expertName = userCache.givenName ?? "";
    const sessionId = useSessionId();
    const partner = usePartner();

    const { dispatcher } = useReactAnalytics();
    const enhancedDispatcher = dispatcher
        .withIdentities({
            ...(expertId && { ExpertId: expertId }),
            ...(callSid && { CallSid: callSid }),
        })
        .withExtra({ tool: "expert-assist" });

    const featuresContext = useMemo(() => {
        return {
            appName: "expert-workspace",
            employeeId: expertId,
            environment: env.mode,
            channel: env.channel,
            partner,
            source: "expert-ui",
        } as ContextProps;
    }, [expertId, partner]);

    return (
        <AnalyticsProvider dispatcher={enhancedDispatcher}>
            <Timeline
                callSid={callSid}
                defaultFeatures={features}
                expertId={expertId}
                expertName={expertName}
                featuresContext={featuresContext}
                guide={guide}
                isFocused={isFocused}
                logger={logger.child({
                    sessionId,
                    callSid,
                })}
                partner={partner}
                sessionId={sessionId}
                SubscriptionsWithFeatures={
                    <SetupSubscriptions
                        loggerWithContext={logger.child({
                            sessionId,
                            callSid,
                        })}
                    />
                }
            />
        </AnalyticsProvider>
    );
}
