import { getLogger } from "@expert/logging";
import { HTTPError } from "ky";
import { getRootDispatcher } from "../../../analytics";
import type { Enrollment, EnrollmentResponse } from "../types";
import { EnrollmentStatus } from "../types";
import { getSessionId } from "../../../sdk";
import { getSalesApiClient } from "./api";

const logger = getLogger({
    module: "enrollment",
    supportTeam: "sales",
});

export const createEnrollment = async (enrollment: Enrollment, isEnrollmentV2Enabled: boolean | undefined) => {
    const childLogger = logger.child({ productOfferId: enrollment.productOfferId, sessionId: getSessionId() });
    try {
        const salesApi = getSalesApiClient();
        childLogger.info("Making enrollment request");
        const enrollmentEndPoint = isEnrollmentV2Enabled ? "v2/enrollment" : "v1/enrollment";
        childLogger.info("Enrollment endpoint", { enrollmentEndPoint });
        const result = await salesApi.post(enrollmentEndPoint, { json: enrollment }).json<EnrollmentResponse>();
        childLogger.info("Finished enrollment request");
        void getRootDispatcher().dispatchBusinessEvent("GetEnrollmentStatus", {
            ProductOfferId: enrollment.productOfferId,
            enrollmentStatus: result.enrollmentStatus,
            StatusOrigin: "ASP",
            productSku: result.productSku,
            confirmationCode: enrollment.confirmationCode,
        });
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            void getRootDispatcher().dispatchBusinessEvent("EnrollmentError", {
                Status: "LAMBDA_ERROR",
                ProductOfferId: enrollment.productOfferId,
                Error: err,
            });
        }

        childLogger.error(err, "enrollment api call failed");
        return { enrollmentStatus: EnrollmentStatus.Error };
    }
};
