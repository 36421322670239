import { ActionIcon, Flex, Text, UnstyledButton } from "@mantine/core";
import { useEffect, useState } from "react";
import { getLogger } from "@expert/logging";
import { CloseX } from "../../../common-ui";
import { sendReaction } from "../api/sendReaction";
import { BaseNotification } from "../../BaseNotification/BaseNotification";
import { reactions, type SaleMadeNotificationType } from "../../models";
import { useAnalytics } from "../../../analytics";
import styles from "./SaleMadeNotification.module.css";

const logger = getLogger({
    module: "SaleMadeNotification",
    supportTeam: "sales",
});

export function SaleMadeNotification({
    onClose,
    seller: { employeeName, employeeId, rank, teamSize, mascot },
}: SaleMadeNotificationType["props"] & {
    onClose: () => void;
}) {
    const { dispatcher } = useAnalytics();
    const [chosenReaction, setChosenReaction] = useState<string | null>(null);
    const [isClosing, setIsClosing] = useState(false);
    setTimeout(() => setIsClosing(true), 10000);

    useEffect(() => {
        void dispatcher.dispatchBusinessEvent("SaleMadeNotificationReceived", { seller: employeeId });
        logger.info({ seller: employeeId }, "SaleMadeNotificationReceived");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BaseNotification onClose={onClose} isClosing={isClosing} side="left">
            <Flex direction="column">
                <Text c="dark.1" fw={700} size="sm">
                    {employeeName} made a sale!
                </Text>
                <Text c="dark.1" size="sm">
                    Now ranked {rank} of {teamSize}
                </Text>
            </Flex>
            <Flex className={styles.buttonContainer} ml="1.5rem">
                {chosenReaction ? (
                    <Text className={styles.fadeIn} size="14px">
                        {chosenReaction} sent
                    </Text>
                ) : (
                    <Text size="14px">Send reaction</Text>
                )}
                {!chosenReaction &&
                    reactions.map((r) => (
                        <Flex key={r.id} pl={6}>
                            <UnstyledButton
                                onClick={() => {
                                    void dispatcher.dispatchBusinessEvent("ReactionSent", {
                                        reactionType: r.id,
                                        sentTo: employeeId,
                                        mascot,
                                    });
                                    logger.info({ seller: employeeId, reactionType: r.id }, "ReactionSent");
                                    setChosenReaction(r.id);
                                    void sendReaction(employeeId, r.id);
                                    setTimeout(() => setIsClosing(true), 3000);
                                }}
                                key={r.id}
                                bg="var(--mantine-color-primary-2)"
                                className={styles.round}
                            >
                                {r.value}
                            </UnstyledButton>
                        </Flex>
                    ))}
                <ActionIcon
                    onClick={() => {
                        void dispatcher.dispatchBusinessEvent("SaleMadeNotificationDismissed");
                        logger.info({ seller: employeeId }, "SaleMadeNotificationDismissed");
                        setIsClosing(true);
                    }}
                    ml="0.5rem"
                    mr={0}
                    bg="transparent"
                >
                    <CloseX fill="var(--mantine-color-primary-1)" />
                </ActionIcon>
            </Flex>
        </BaseNotification>
    );
}
