import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { useEffect, useMemo } from "react";
import { useTimelineStore } from "@soluto-private/expert-workspace-timeline";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { trackFullStoryEvent } from "@expert/monitoring";
import type { ReactiveMessagePayload } from "@expert/shared-types";
import { isAutoCompleteMessage } from "./validators";

export const useGaiaAutoCompleteEvents = (loggerProp: Logger) => {
    const logger = useMemo(() => loggerProp.child({ module: "useGaiaAutocompleteEvents" }), [loggerProp]);
    const { dispatcher } = useReactAnalytics();

    useEffect(() => {
        const gaiaEventBusFetchAutoCompleteSuccess = gaiaWsEventBus.on(
            "gaia_ws_orchestrator-invoke-success",
            ({ body }: GaiaWebSocketResponse) => {
                if (!isAutoCompleteMessage(body as ReactiveMessagePayload)) return;
                const { suggestions } = (body ?? {}) as ReactiveMessagePayload;
                logger.info({ suggestions }, "GAIA | AutoComplete | websocket received autocomplete suggestion(s)");

                void dispatcher.dispatchBusinessEvent("AutoComplete_SuggestionReceived", {
                    suggestions,
                });
                trackFullStoryEvent("AutoComplete_SuggestionReceived", { suggestions });

                const parsedSuggestions = suggestions?.map(({ text }) => text);
                const dedupedSuggestions = [...new Set(parsedSuggestions)];

                useTimelineStore.getState().setNewSuggestions(dedupedSuggestions);
            },
        );

        return () => {
            gaiaEventBusFetchAutoCompleteSuccess();
        };
    }, [dispatcher, logger]);
};
