import { ActionIcon, Flex, Group, Pill, Progress, Stack, Tooltip, Transition } from "@mantine/core";
import { useEffect, useMemo, useRef, useState } from "react";
import type { Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { trackFullStoryEvent } from "@expert/monitoring";
import { defaultErrorStep, getStep } from "../steps";
import { type CloseGuideSource, type SolveGuidedFlow, type SolveGuidedFlowAction } from "../types";
import { useSolveGuideStore } from "../store";
import { InfoIcon } from "../assets";
import { GuideTag } from "./GuideTag";
import { GuideActionGroup } from "./GuideActionGroup";
import { GuideCloseConfirmation } from "./GuideCloseConfirmation";
import classes from "./styles.module.css";
import { GuideRestartConfirmation } from "./GuideRestartConfirmation";
import { GuideNavigation } from "./GuideNavigation";

interface GuideCardProps {
    flow: SolveGuidedFlow;
    opened: boolean;
    onGuideClose: (source: CloseGuideSource) => void;
    logger: Logger;
    callSid?: string;
}

export function GuideCard({
    flow,
    opened,
    onGuideClose,
    logger: loggerProp,
    callSid,
}: GuideCardProps): JSX.Element | null {
    const {
        currentStepId = flow.firstStepId,
        setCurrentStepId,
        previousSteps,
        setPreviousSteps,
        setDisableAutomaticOpen,
    } = useSolveGuideStore();
    const currentStep = getStep(flow, currentStepId);
    const logger = useMemo(
        () => loggerProp.child({ module: "GuideCard", guide: flow.flowId, currentStepId, supportTeam: "solve" }),
        [loggerProp, currentStepId, flow.flowId],
    );
    const { dispatcher } = useReactAnalytics();
    const enhancedDispatcher = callSid ? dispatcher.withIdentities({ CallSid: callSid }) : dispatcher;

    const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState(false);
    const [isRestartConfirmationOpen, setIsRestartConfirmationOpen] = useState(false);
    const [isOverflowing, setIsOverflowing] = useState(true);
    const [lastScrollEventStepId, setLastScrollEventStepId] = useState("");
    const overflowRef = useRef<HTMLDivElement>(null);

    // check if the content is overflowing in the card
    useEffect(() => {
        setIsOverflowing(
            overflowRef.current?.scrollHeight
                ? overflowRef.current.scrollHeight > overflowRef.current.clientHeight
                : false,
        );
    }, [overflowRef.current?.scrollHeight, overflowRef.current?.clientHeight, currentStepId]);

    // scroll to top when step changes
    useEffect(() => {
        overflowRef.current?.scrollTo(0, 0);
    }, [currentStepId]);

    const onNext = ({ onClick, nextStepId, nextStepAction, validate }: SolveGuidedFlowAction) => {
        const validationError = validate?.();
        if (validationError) return;

        try {
            logger.info("Solve Guide | Click next (navigate to next step)");
            const updatedPreviousSteps = [...previousSteps, currentStepId];
            setPreviousSteps(updatedPreviousSteps);

            void enhancedDispatcher.dispatchBusinessEvent(`Click_Guide_NextStep`, {
                button: "NextStep",
                guide: flow.flowId,
                currentStepId,
                nextStepId: nextStepId ?? nextStepAction ?? "Unknown",
                completedSteps: updatedPreviousSteps.join(","),
                completedStepCount: updatedPreviousSteps.length,
            });

            onClick?.();
            if (nextStepId) {
                const nextStep = getStep(flow, nextStepId);
                if (nextStep.progress === 100) {
                    onGuideCompleted(nextStepId, [...updatedPreviousSteps, nextStepId]);
                }
                setCurrentStepId(nextStep.stepId);
            } else if (nextStepAction) {
                switch (nextStepAction) {
                    case "CloseGuide":
                        setDisableAutomaticOpen(true);
                        onGuideClose("closeButton");
                        break;
                    case "PreviousStep":
                        onBack(false);
                        break;
                }
            } else {
                logger.error(
                    "Solve Guide | Unable to handle guide next step - no nextStepId or nextStepAction provided",
                );
                setCurrentStepId(defaultErrorStep.stepId);
            }
        } catch (err) {
            logger.error("Solve Guide | Unable to handle guide next step");
            setCurrentStepId(defaultErrorStep.stepId);
        }
    };

    const onBack = (sendAnalytics = true) => {
        logger.info("Solve Guide | Click back (navigate to previous step)");

        try {
            const updatedPreviousSteps = [...previousSteps];
            const previousStepId = updatedPreviousSteps.pop();
            if (previousStepId) {
                const previousStep = getStep(flow, previousStepId);
                if (sendAnalytics) {
                    void enhancedDispatcher.dispatchBusinessEvent(`Click_Guide_PreviousStep`, {
                        button: "PreviousStep",
                        guide: flow.flowId,
                        currentStepId,
                        nextStepId: previousStepId,
                        completedSteps: updatedPreviousSteps.join(","),
                        completedStepCount: updatedPreviousSteps.length,
                    });
                }
                setCurrentStepId(previousStep.stepId);
                setPreviousSteps(updatedPreviousSteps);
            }
        } catch (err) {
            logger.error("Solve Guide | Unable to handle guide previous step");
            setCurrentStepId(defaultErrorStep.stepId);
        }
    };

    const onRestart = () => {
        logger.info("Solve Guide | Click restart guide (navigate to first step)");

        void enhancedDispatcher.dispatchBusinessEvent(`Click_Guide_RestartGuide`, {
            button: "RestartGuide",
            guide: flow.flowId,
            currentStepId,
            nextStepId: flow.firstStepId,
            completedSteps: previousSteps.join(","),
            completedStepCount: previousSteps.length,
        });

        setIsRestartConfirmationOpen(true);
    };

    const onRestartConfirmed = () => {
        logger.info("Solve Guide | Click confirm restart guide");

        setIsRestartConfirmationOpen(false);

        flow.reset();
        setCurrentStepId(flow.firstStepId);
        setPreviousSteps([]);
    };

    const onClose = () => {
        logger.info("Solve Guide | Click close guide");
        void enhancedDispatcher.dispatchBusinessEvent(`Click_Guide_CloseGuide`, {
            button: "CloseGuide",
            guide: flow.flowId,
            currentStepId,
        });
        // if guide is complete, close the guide; else show close confirmation check
        if (currentStep.progress === 100) {
            setDisableAutomaticOpen(true);
            onGuideClose("closeButton");
        } else {
            setIsCloseConfirmationOpen(true);
        }
    };

    const onCloseConfirmed = () => {
        logger.info(`Solve Guide | Click Confirm close guide`);
        setDisableAutomaticOpen(true);
        setIsCloseConfirmationOpen(false);
        onGuideClose("closeButton");
    };

    const onBottomReached = () => {
        // only send scroll event once per step
        if (currentStepId === lastScrollEventStepId) return;
        setLastScrollEventStepId(currentStepId);
        logger.info({ guide: flow.flowId, currentStepId }, `Solve Guide | Scrolled to bottom`);
        void enhancedDispatcher.dispatchBusinessEvent(`Guide_ScrolledToEnd`, {
            guide: flow.flowId,
            currentStepId,
        });
    };

    const onScroll = ({ currentTarget: { scrollTop, scrollHeight, clientHeight } }: React.UIEvent<HTMLElement>) => {
        // TODO: use ScrollArea.onBottomReached directly when mantine upgraded 7.10.2 -> 7.12.2
        if (Math.abs(scrollTop - (scrollHeight - clientHeight)) < 1) {
            onBottomReached();
        }
    };

    const onGuideCompleted = (finalStepId: string, completedSteps: string[]) => {
        logger.info({ guide: flow.flowId, currentStepId: finalStepId }, `Solve Guide | Completed guide`);
        void enhancedDispatcher.dispatchBusinessEvent(`Guide_CompletedGuide`, {
            guide: flow.flowId,
            currentStepId: finalStepId,
            completedSteps: completedSteps.join(","),
            completedStepCount: completedSteps.length,
        });
        trackFullStoryEvent("Guide_CompletedGuide", {
            guide: flow.flowId,
            currentStepId: finalStepId,
        });
    };

    return (
        <>
            <Transition mounted={opened} transition="fade-down" duration={400} timingFunction="ease">
                {(styles) => (
                    <Stack
                        className={classes.guideCard}
                        variant={currentStep.variant}
                        style={styles}
                        h="13.4rem"
                        w="51vw"
                        maw="48rem"
                        miw="32rem"
                        p="0rem"
                    >
                        <Progress
                            value={currentStep.progress}
                            color="success.4"
                            h="0.25rem"
                            mt="0rem"
                            bg={currentStep.progress === 0 ? "var(--mantine-color-primary-6)" : "transparent"}
                        />
                        <Stack p="0.75rem" justify="space-between" gap="1rem" w="100%" h="100%" pos="absolute">
                            <Group w="100%" justify="space-between">
                                <Group align="center" gap="xs">
                                    <Pill.Group>
                                        <GuideTag>
                                            {currentStep.progress === 0
                                                ? flow.tag
                                                : `${flow.tag} - ${currentStep.progress}%`}
                                        </GuideTag>
                                        {currentStep.tag && (
                                            <GuideTag bg="primary.4" c="var(--mantine-color-white)">
                                                {currentStep.tag}
                                            </GuideTag>
                                        )}
                                    </Pill.Group>
                                    {flow.tooltip && (
                                        <Tooltip label={flow.tooltip} position="right-start">
                                            <ActionIcon bg="transparent" p="0.25rem" h="1.5rem">
                                                <InfoIcon />
                                            </ActionIcon>
                                        </Tooltip>
                                    )}
                                </Group>
                                <GuideNavigation
                                    hasPreviousSteps={previousSteps.length > 0}
                                    onBack={onBack}
                                    onRestart={onRestart}
                                    onClose={onClose}
                                />
                            </Group>
                            <Flex
                                align="flex-start"
                                justify="flex-start"
                                className={isOverflowing ? classes.contentScrollMask : undefined}
                                ref={overflowRef}
                                styles={{
                                    root: {
                                        overflowY: "auto",
                                        paddingRight: "6rem",
                                        paddingBottom: isOverflowing ? "0.75rem" : "0.15rem",
                                        overflowX: "hidden",
                                    },
                                }}
                                onScroll={onScroll}
                            >
                                {currentStep.content}
                            </Flex>
                            <GuideActionGroup currentStep={currentStep} onClick={onNext} />
                        </Stack>
                    </Stack>
                )}
            </Transition>
            <GuideCloseConfirmation
                isOpen={isCloseConfirmationOpen}
                guideId={flow.flowId}
                title={flow.feedbackOptions.title}
                options={flow.feedbackOptions.options}
                onCancel={() => {
                    logger.info(`Solve Guide | Cancelled close guide`);
                    setIsCloseConfirmationOpen(false);
                }}
                onClose={onCloseConfirmed}
            />
            <GuideRestartConfirmation
                title="Restart the activation flow?"
                isOpen={isRestartConfirmationOpen}
                onClose={() => {
                    logger.info(`Solve Guide | Cancelled restart guide`);
                    setIsRestartConfirmationOpen(false);
                }}
                onSubmit={onRestartConfirmed}
            />
        </>
    );
}
