const deviceActivationFeatures = {
    isConversationalIVRGuideEnabled: false,
    isDeviceActivationGuideEnabled: false,
    isDeviceReplacementInfoEnabled: false,
};

const workspaceHeaderFeatures = {
    isProactiveEnabled: false,
};

const workspaceCustomerFeatures = {
    isPreviousSessionSummaryEnabled: false,
};

export const workspaceFeatures = {
    ...deviceActivationFeatures,
    ...workspaceHeaderFeatures,
    ...workspaceCustomerFeatures,
};
