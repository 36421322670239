import { Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import { useTimer } from "react-timer-hook";
import { useAnalytics } from "../../../analytics";
import { ThumbsUp } from "./icons";
import classes from "./MicroLearning.module.css";

interface FeedbackSectionProps {
    hideSection: () => void;
    question: string;
}
type FeedbackOption = "thumbsUp" | "thumbsDown";

export function FeedbackSection({ hideSection, question }: FeedbackSectionProps): JSX.Element {
    const { dispatcher } = useAnalytics();
    const [showFeedbackResponse, setShowFeedbackResponse] = useState(false);
    const [hasResponded, setHasResponded] = useState(false);

    const { start } = useTimer({
        expiryTimestamp: new Date(Date.now() + 2000),
        autoStart: false,
        onExpire: () => hideSection(),
    });

    const clickFeedback = (option: FeedbackOption): void => {
        setHasResponded(true);
        if (option === "thumbsUp") {
            setShowFeedbackResponse(true);
            void dispatcher.dispatchBusinessEvent("ButtonClick_FeedbackThumbsUp", { question });
        } else {
            setShowFeedbackResponse(false);
            void dispatcher.dispatchBusinessEvent("ButtonClick_FeedbackThumbsDown", { question });
        }
        start();
    };

    return (
        <Flex w="100%" className={classes.feedback}>
            {!hasResponded ? (
                <>
                    <Text pt={24} fz={14} pr={8}>
                        Did this teach you something new?
                    </Text>
                    <Flex pt={12}>
                        <Button
                            onClick={() => clickFeedback("thumbsUp")}
                            variant="light"
                            color="{dark.7}"
                            size="md"
                            className={classes.button}
                        >
                            <ThumbsUp />
                        </Button>
                        <Button
                            onClick={() => clickFeedback("thumbsDown")}
                            variant="light"
                            color="{dark.7}"
                            size="md"
                            className={classes.button}
                        >
                            <ThumbsUp y="-1" />
                        </Button>
                    </Flex>
                </>
            ) : showFeedbackResponse ? (
                <Text pt={24} fz={14} pr={8}>
                    Great!
                </Text>
            ) : (
                <Text pt={24} fz={14} pr={8}>
                    Ok, thank you
                </Text>
            )}
        </Flex>
    );
}
