import { getSessionId } from "../../../sdk";
import { env } from "../../../../config";
import { api } from "../../../shared-utils";

export const getSalesApiClient = () => {
    return api.extend(() => ({
        prefixUrl: env.salesBaseApiUrl,
        hooks: {
            beforeRequest: [
                (request) => {
                    request.headers.set("x-ewp-correlation-id", getSessionId());
                },
            ],
        },
    }));
};

export const getGamificationApiClient = () => {
    return api.extend(() => ({
        prefixUrl: env.gamificationBaseApiUrl,
        hooks: {
            beforeRequest: [
                (request) => {
                    request.headers.set("x-ewp-correlation-id", getSessionId());
                },
            ],
        },
    }));
};
