import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { type CustomerVerificationStep } from "../types";

interface VerifiedCustomerState {
    stepHistory: CustomerVerificationStep[];
    pushStep: (step: CustomerVerificationStep) => void;
    popStep: VoidFunction;
    resetSteps: VoidFunction;
}

export const useVerificationFlowStore = createWithEqualityFn<VerifiedCustomerState>()(
    persist(
        immer(
            devtools(
                (set) => ({
                    stepHistory: ["CustomerPhoneInput"],
                    pushStep: (step: CustomerVerificationStep) =>
                        set((state) => void state.stepHistory.push(step), false, "pushStep"),
                    popStep: () => set((state) => void state.stepHistory.pop(), false, "popStep"),
                    resetSteps: () => set({ stepHistory: ["CustomerPhoneInput"] }, false, "resetSteps"),
                }),
                {
                    enabled: import.meta.env.MODE !== "production",
                    store: "verification-flow-store",
                    name: "workspace/customer-panel",
                },
            ),
        ),
        { name: "verification-flow-store", skipHydration: true },
    ),
    shallow,
);
