import { devtools, persist } from "zustand/middleware";
import { createWithEqualityFn } from "zustand/traditional";
import type { Customer, DeviceReplacement, SessionSummary } from "../api";

export interface CustomerState {
    customer?: Customer;
    deviceReplacement?: DeviceReplacement;
    previousSummary?: SessionSummary;
    setPreviousSummary: (previousSummary?: SessionSummary) => void;

    setCustomer: (
        customer?: Partial<Customer & { mdn: string }>,
        deviceReplacement?: DeviceReplacement,
        previousSummary?: SessionSummary,
    ) => void;
    reset: () => void;
}

export const useVerifiedCustomerStore = createWithEqualityFn<CustomerState>()(
    persist(
        devtools(
            (set) => ({
                customer: undefined,
                deviceReplacement: undefined,
                setPreviousSummary: (previousSummary?: SessionSummary) =>
                    set({ previousSummary }, false, "setPreviousSummary"),
                setCustomer: (
                    customer?: Partial<Customer & { mdn: string }>,
                    deviceReplacement?: DeviceReplacement,
                    previousSummary?: SessionSummary,
                ) => set({ customer, deviceReplacement, previousSummary }, false, "setCustomer"),
                reset: () =>
                    set(
                        { deviceReplacement: undefined, customer: undefined, previousSummary: undefined },
                        false,
                        "reset",
                    ),
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "verified-customer-store",
                name: "workspace/customer-info",
            },
        ),
        { name: "verified-customer-store", skipHydration: true },
    ),
);
