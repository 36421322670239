import React, { useState } from "react";
import { ActionIcon, NumberInput } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { updateQueueVolume } from "../../api";
import type { QueueTrafficInfo } from "../../models";

export function PercentageInput({ queueInfo }: { queueInfo: QueueTrafficInfo }): React.JSX.Element {
    const [finalPercentage, setFinalPercentage] = useState<number>(queueInfo.percentage);
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();
    return (
        <NumberInput
            rightSectionWidth="4rem"
            rightSection={
                <ActionIcon
                    loading={isLoading}
                    w="100%"
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                        setIsLoading(true);
                        await updateQueueVolume([
                            {
                                queueSid: queueInfo.queueSid,
                                queueName: queueInfo.queueName,
                                percentage: finalPercentage,
                            },
                        ]);
                        await queryClient.invalidateQueries({ queryKey: ["traffic-control-all-queues"] });
                        setIsLoading(false);
                    }}
                    disabled={queueInfo.percentage === finalPercentage}
                >
                    Save
                </ActionIcon>
            }
            clampBehavior="strict"
            allowDecimal={false}
            suffix="%"
            min={0}
            max={100}
            defaultValue={finalPercentage}
            onChange={(ev) => setFinalPercentage(ev as number)}
        />
    );
}
