export interface SeparatorProps {
    text: string;
    callDuration?: string;
    timestamp?: string;
}

export function getSeparatorLabel({ text, callDuration, timestamp }: SeparatorProps) {
    let label = text;
    if (timestamp) {
        label += ` - ${timestamp}`;
    }
    if (callDuration) {
        label += ` | Duration ${callDuration}`;
    }
    return label;
}
