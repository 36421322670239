import { Button, Flex } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { useToast } from "../../common-ui";
import { useSessionId } from "../../sdk";
import { HeaderControls } from "./HeaderControls";
import classes from "./WorkspaceHeader.module.css";
import { AsurionHolidayLogo } from "../../common-ui";

export function WorkspaceHeader(): JSX.Element {
    const clipboard = useClipboard({ timeout: 500 });
    const toast = useToast();
    const sessionId = useSessionId();
    const copyToClipboard = () => {
        clipboard.copy(sessionId);
        toast.success("Session ID copied to clipboard");
    };
    return (
        <Flex align="center" className={classes.headerContainer}>
            <Flex>
                <Flex className={classes.logo}>
                    <AsurionHolidayLogo />
                </Flex>
                <Button fz="14px" variant="transparent" onClick={copyToClipboard} className={classes.text} size="xs">
                    Session ID: {sessionId}
                </Button>
            </Flex>
            <HeaderControls />
        </Flex>
    );
}
