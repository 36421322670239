import { type ExpertAssistMessage } from "../shared-types";
import { getMessage } from "./getMessages";
import { useTimelineStore } from "./timelineStore";

export const updateMessage = (id: string, message: Partial<ExpertAssistMessage>) => {
    const currentMessage = getMessage(id);
    if (!currentMessage) return;
    const updatedMessage = { ...currentMessage, ...message };
    useTimelineStore.getState().updateMessage(id, updatedMessage);
};

export const deactivateMessage = (id: string) => {
    useTimelineStore.getState().updateMessage(id, {
        status: "deactivated",
    });
};

const deactivatedSummaryMessage = "The previous session notes were removed.";
export const deactivatePreviousSessionSummaryMessage = (timestamp: string) => {
    const { messages, updateMessage } = useTimelineStore.getState();
    /* find _last_ session summary message for timestamp - this ensures we're deactivating the most recent message in case a session summary message earlier in the timeline had the same timestamp */
    const existingPreviousSummaryMessage = messages
        .filter((msg) => msg.type === "SessionSummary" && msg.metadata?.sessionSummary?.timestamp === timestamp)
        .pop();
    if (!existingPreviousSummaryMessage || !existingPreviousSummaryMessage.metadata?.sessionSummary) {
        return;
    }
    deactivateMessage(existingPreviousSummaryMessage.id);
    updateMessage(existingPreviousSummaryMessage.id, {
        metadata: {
            sessionSummary: {
                ...existingPreviousSummaryMessage.metadata.sessionSummary,
                summary: deactivatedSummaryMessage,
            },
        },
        text: deactivatedSummaryMessage,
    });
};
