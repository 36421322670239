import { bool, customCleanEnv, str, url } from "envalid";
import type { LevelOrString } from "pino";
import type { Channel } from "../src/shared-types";

interface Env {
    mode: string;
    isProdMode: boolean;
    commitHash: string;
    appName: string;
    channel: Channel;
    expertWorkspaceWebSocketUrl: string;
    gaiaWebsocketUrl: string;
    gaiaApiUrl: string;
    fullstoryOrgId: string;
    dynatraceEnabled: boolean;
    dynatraceAppMonitoringUrl: string;
    appcuesEnabled: boolean;
    appcuesUrl: string;
    viteUcsdkFilepath?: string;
    apiBaseUrl: string;
    salesBaseApiUrl: string;
    gamificationBaseApiUrl: string;
    educationBaseApiUrl: string;
    customerDetailsBaseApiUrl: string;
    aspWebsocketUrl: string;
    contentful: {
        accessToken: string;
        space: string;
        host: string;
    };
    expertFeedbackUrl: string;
    logCollectorUrl: `${string}/log`;
    logLevel: {
        api: LevelOrString;
        console: LevelOrString;
    };
    ivrDetailsUrl: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        MODE: str(),
        PROD: bool(),
        COMMIT_HASH: str(),
        VITE_APP_NAME: str(),
        VITE_GAIA_WEBSOCKET_URL: url(),
        VITE_GAIA_API_URL: url(),
        VITE_FULLSTORY_ORG_ID: str(),
        VITE_CHANNEL: str({ choices: ["voice", "messaging"] }),
        VITE_DYNATRACE_ENABLED: bool(),
        VITE_DYNATRACE_APP_MONITORING_URL: str(),
        VITE_UCSDK_FILEPATH: str(),
        VITE_APPCUES_ENABLED: bool(),
        VITE_APPCUES_URL: str(),
        VITE_API_BASE_URL: url(),
        VITE_SALES_API_BASE_URL: url(),
        VITE_GAMIFICATION_API_BASE_URL: url(),
        VITE_CUSTOMER_DETAILS_API_BASE_URL: url(),
        VITE_EDUCATION_API_BASE_URL: url(),
        VITE_SALES_WEBSOCKET: url(),
        VITE_CONTENTFUL_ACCESS_TOKEN: str(),
        VITE_CONTENTFUL_SPACE: str(),
        VITE_CONTENTFUL_HOST: str(),
        VITE_LOG_LEVEL_CONSOLE: str({ default: "trace" }),
        VITE_LOG_LEVEL_API: str({ default: "warn" }),
        VITE_IVR_DETAILS_URL: url(),
        VITE_WEBSOCKET_URL: url(),
    },
    (inputEnv): Env => ({
        mode: inputEnv.MODE,
        isProdMode: inputEnv.MODE === "production" && inputEnv.PROD,
        commitHash: inputEnv.COMMIT_HASH,
        appName: inputEnv.VITE_APP_NAME,
        channel: inputEnv.VITE_CHANNEL,
        gaiaWebsocketUrl: inputEnv.VITE_GAIA_WEBSOCKET_URL,
        gaiaApiUrl: inputEnv.VITE_GAIA_API_URL,
        fullstoryOrgId: inputEnv.VITE_FULLSTORY_ORG_ID,
        dynatraceEnabled: inputEnv.VITE_DYNATRACE_ENABLED,
        dynatraceAppMonitoringUrl: inputEnv.VITE_DYNATRACE_APP_MONITORING_URL,
        viteUcsdkFilepath: inputEnv.VITE_UCSDK_FILEPATH,
        appcuesEnabled: inputEnv.VITE_APPCUES_ENABLED,
        appcuesUrl: inputEnv.VITE_APPCUES_URL,
        apiBaseUrl: inputEnv.VITE_API_BASE_URL,
        salesBaseApiUrl: inputEnv.VITE_SALES_API_BASE_URL,
        gamificationBaseApiUrl: inputEnv.VITE_GAMIFICATION_API_BASE_URL,
        educationBaseApiUrl: inputEnv.VITE_EDUCATION_API_BASE_URL,
        customerDetailsBaseApiUrl: inputEnv.VITE_CUSTOMER_DETAILS_API_BASE_URL,
        aspWebsocketUrl: inputEnv.VITE_SALES_WEBSOCKET,
        contentful: {
            accessToken: inputEnv.VITE_CONTENTFUL_ACCESS_TOKEN,
            space: inputEnv.VITE_CONTENTFUL_SPACE,
            host: inputEnv.VITE_CONTENTFUL_HOST,
        },
        expertFeedbackUrl: `${inputEnv.VITE_API_BASE_URL}/api/v1/feedback/expert`,
        logCollectorUrl: `${inputEnv.VITE_API_BASE_URL}/log`,
        logLevel: {
            api: inputEnv.VITE_LOG_LEVEL_API,
            console: inputEnv.VITE_LOG_LEVEL_CONSOLE,
        },
        ivrDetailsUrl: inputEnv.VITE_IVR_DETAILS_URL,
        expertWorkspaceWebSocketUrl: inputEnv.VITE_WEBSOCKET_URL,
    }),
);
