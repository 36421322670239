import { Flex, Text } from "@mantine/core";
import classes from "./WidgetRow.module.css";

interface WidgetRowProps {
    largeText: string;
    smallText: string;
    SVGIcon?: React.ElementType;
}

export function WidgetRow({ largeText, smallText, SVGIcon }: WidgetRowProps): JSX.Element {
    return (
        <Flex gap={8}>
            {SVGIcon && (
                <Flex align="center">
                    <SVGIcon />
                </Flex>
            )}

            <Flex align="baseline">
                <Text fz={14} c="dark.0" className="fs-mask">
                    {largeText}
                </Text>
                <Text fz={12} c="dark.2" className={`${classes.whitespace} fs-mask`}>
                    {smallText}
                </Text>
            </Flex>
        </Flex>
    );
}
