import { Navigate, Outlet } from "react-router";
import { useSetupSsoToken } from "../auth";
import { useFeatureFlag } from "../shared-utils";

export function PerformanceMetricsRoutes() {
    useSetupSsoToken();
    const { data: isMetricsPageEnabled, isLoading: isMetricsPageLoading } =
        useFeatureFlag("Sales-isMetricsPageEnabled");
    if (isMetricsPageLoading) {
        return null;
    }
    return isMetricsPageEnabled ? <Outlet /> : <Navigate to="/" />;
}
