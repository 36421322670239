import { getLogger } from "@expert/logging";
import { HTTPError } from "ky";
import { Partner } from "../../shared-types";
import { api } from "../../shared-utils";
import { env } from "../environment";
import { ChannelShareDatabasePayload } from "./types";

const logger = getLogger({
    module: "getChannelShareData",
});

const channel = "IVR";

/**
 * @see https://ivr3.dev.rtt.contactcenter.npr.aws.asurion.net/docs#post-/api/v1/csd/data
 * @see https://twilio-ui-admin-qa.cc-nonprod.asurion53.com/csd
 */
export async function getChannelShareData(
    callId: string,
    client: Partner,
): Promise<ChannelShareDatabasePayload | null> {
    try {
        const data = await api
            .post("api/v1/csd/data", { prefixUrl: env.channelShareDatabaseBaseUrl, json: { channel, client, callId } })
            .json<{ RESPONSE: { DATA: { id: string; value: string }[] } }>();
        return Object.fromEntries(data.RESPONSE.DATA.map(({ id, value }) => [id, value]));
    } catch (err: unknown) {
        const status = err instanceof HTTPError && err.response.status;
        logger.error({ err, status }, "Channel Share Database API call failed");
        return null;
    }
}
