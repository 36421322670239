import { getGamificationApiClient, getSalesApiClient } from "../../home-product/api/api";
import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { env } from "../../../../config";
import { userCache } from "../../../shared-utils";

export interface SalesLeaderboardEntry {
    employeeId: string;
    employeeName: string;
    numSales: number;
    order: number;
}

export const getCurrentSalesLeaderboard = async (): Promise<SalesLeaderboardEntry[]> => {
    const isLeaderboardV2Enabled = await getFeature<boolean>(
        "EWP-isLeaderboardV2Enabled",
        {
            environment: env.mode,
            employeeId: userCache.employeeId,
        },
        0,
    );

    if (isLeaderboardV2Enabled) {
        return await getGamificationApiClient()
            .get("v1/leaderboards/sales/3002", { retry: 0 })
            .json<SalesLeaderboardEntry[]>();
    }

    return await getSalesApiClient().get("v2/leaderboards/sales/3002", { retry: 0 }).json<SalesLeaderboardEntry[]>();
};
