import { Container, Flex, Text } from "@mantine/core";
import React from "react";
import { GreenDot } from "../Icons/GreenDot";
import { usePartner } from "../../../../sdk";
import { type Partner } from "../../../../shared-types";
import classes from "./PlanHighlights.module.css";
import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

const getStaticPlanHighlights = (partner: Partner) => {
    const partnerString = partner === "att" ? "AT&T" : partner;
    return [
        "Repair, replacement, or reimbursement for home tech",
        "24/7 premium tech support",
        "Covers future home tech purchased—no receipts needed",
        `Covers eligible products—both ${capitalizeFirstLetter(partnerString)} and non-${capitalizeFirstLetter(partnerString)}`,
    ];
};

export function StaticPlanHighlights(): JSX.Element | null {
    const partner = usePartner();
    return (
        <Flex align="start" direction="column">
            <Text fw="var(--mantine-font-weight-normal)">Plan highlights</Text>
            {getStaticPlanHighlights(partner).map((highlight) => {
                return (
                    <Flex mt="1rem" key={highlight}>
                        <Container mr="1rem" p={0} maw="2rem" mah="2rem">
                            <GreenDot />
                        </Container>
                        <Text className={classes.highlightText} color="dark.1" size="sm">
                            {highlight}
                        </Text>
                    </Flex>
                );
            })}
        </Flex>
    );
}
