import { type WorkspaceFeatures, useFeatures } from "@expert/features";
import { type Logger } from "@expert/logging";
import { type VoiceTask, type usePartner } from "../../sdk";
import { useDeviceActivationGuide } from "./useDeviceActivationGuide";
import { useConversationalIVR } from "./useConversationalIVR";
import { useActivationGuideDisclosure } from "./useActivationGuideDisclosure";
import { useAutomaticActivationGuide } from "./useAutomaticActivationGuide";
import { useGuideCommand } from "./useGuideCommand";
import { usePendingDeviceInfo } from "./usePendingDeviceInfo";

interface SetupActivationGuideProps {
    // Session/task
    isCustomerSession: boolean;
    partner: ReturnType<typeof usePartner>;
    activeVoiceTask: VoiceTask | null;

    // Other
    logger: Logger;
}

export const useSetupActivationGuide = ({
    isCustomerSession,
    partner,
    activeVoiceTask,
    logger,
}: SetupActivationGuideProps) => {
    const {
        features: { isDeviceActivationGuideEnabled, isConversationalIVRGuideEnabled },
    } = useFeatures<WorkspaceFeatures>();

    const isActivationGuideEnabled = isDeviceActivationGuideEnabled || isConversationalIVRGuideEnabled;
    const isPhase1ConversationalIVR = !isDeviceActivationGuideEnabled && isConversationalIVRGuideEnabled;

    const { isGuideOpen, openGuide, closeGuide } = useActivationGuideDisclosure(logger, activeVoiceTask?.agentCallId);
    const guide = useDeviceActivationGuide();

    useConversationalIVR(isConversationalIVRGuideEnabled, activeVoiceTask?.customerOriginalCallId);
    usePendingDeviceInfo(isPhase1ConversationalIVR);
    useAutomaticActivationGuide({
        isActivationGuideEnabled,
        isPhase1ConversationalIVR,
        isCustomerSession,
        partner,
        supportNumberCalled: activeVoiceTask?.supportNumberCalled,
        isGuideOpen,
        openGuide,
        closeGuide,
    });

    const guideCommand = useGuideCommand({
        isActivationGuideEnabled,
        isPhase1ConversationalIVR,
        openGuide,
    });

    return {
        isActivationGuideEnabled,
        guide,
        isGuideOpen,
        guideCommand,
        closeGuide,
    };
};
