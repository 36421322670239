import { useDisclosure } from "@mantine/hooks";
import { type Logger } from "@expert/logging";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { type CloseGuideSource, type OpenGuideSource, useSolveGuideStore } from "@expert/solve-tools";
import { trackFullStoryEvent } from "@expert/monitoring";
import { DeviceActivationFirstStepId, DeviceActivationFlowId } from "../types";
import { useDeviceActivationStore } from "../store";
import { type Session } from "../../sdk/sessions/types";
import { type VoiceTask } from "../../sdk";

export const useActivationGuideDisclosure = (logger: Logger, callSid?: string) => {
    const { dispatcher } = useReactAnalytics();
    const enhancedDispatcher = callSid ? dispatcher.withIdentities({ CallSid: callSid }) : dispatcher;

    const getCurrentStepId = () => {
        return useSolveGuideStore.getState().currentStepId ?? DeviceActivationFirstStepId;
    };

    const getExtraAnalytics = () => {
        const { includesDetails } = useDeviceActivationStore.getState();
        const { previousSteps, currentStepId } = useSolveGuideStore.getState();
        return {
            ...includesDetails,
            completedSteps: [...previousSteps, currentStepId].join(","),
            completedStepCount: previousSteps.length + 1,
        };
    };

    const [isGuideOpen, { close, open }] = useDisclosure(false);

    const openGuide = (source: OpenGuideSource) => {
        open();
        const currentStepId = getCurrentStepId();
        const extra = getExtraAnalytics();
        logger.info({ guide: DeviceActivationFlowId, source, currentStepId }, "Solve Guide | open");
        void enhancedDispatcher.dispatchBusinessEvent("Guide_OpenGuide", {
            guide: DeviceActivationFlowId,
            currentStepId,
            source,
            ...extra,
        });
        trackFullStoryEvent("Guide_OpenGuide", {
            guide: DeviceActivationFlowId,
            currentStepId,
            source,
            callSid,
            ...extra,
        });
        useSolveGuideStore.getState().setDisableAutomaticOpen(false);
    };

    const closeGuide = (source: CloseGuideSource, previousSession?: Session) => {
        close();
        const currentStepId = getCurrentStepId();
        logger.info({ guide: DeviceActivationFlowId, source, currentStepId }, "Solve Guide | close");
        // when guide auto-closes at the end of a call, the session info on the dispatcher has already updated to the new session info
        // extraEnhancedDispatcher allows us to properly send CloseGuide event with previous session info

        const callSid = (previousSession?.tasks.at(-1) as VoiceTask)?.agentCallId;
        const extraEnhancedDispatcher = previousSession
            ? enhancedDispatcher.withIdentities({ SessionId: previousSession.id, CallSid: callSid })
            : enhancedDispatcher;

        const extra = getExtraAnalytics();
        void extraEnhancedDispatcher.dispatchBusinessEvent("Guide_CloseGuide", {
            guide: DeviceActivationFlowId,
            currentStepId,
            source,
            ...extra,
        });
        trackFullStoryEvent("Guide_CloseGuide", {
            guide: DeviceActivationFlowId,
            currentStepId,
            source,
            callSid,
            ...extra,
        });
    };

    return {
        isGuideOpen,
        openGuide,
        closeGuide,
    };
};
