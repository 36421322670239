import { Button } from "@mantine/core";
import { getRootDispatcher, withPolymorphicClickAnalytics } from "../../../analytics";
import { type VoiceTask, useAgentSdk } from "../../../sdk";
import { type Contact } from "../../../shared-types";
import { useAsyncLoading, isInternalTfn } from "../../../shared-utils";
import { useControlsStore, withControlsLock } from "../../CallControls/controls.store";
import { useContactsStateStore } from "../vdnContacts.store";

interface TransferCallButtonProps {
    task: VoiceTask;
    transferVdn: Contact | undefined;
    transferTfn: string | undefined;
}

export function TransferCallButton({ task, transferVdn, transferTfn }: TransferCallButtonProps) {
    const agentSdk = useAgentSdk();
    const { contacts } = useContactsStateStore();
    const { controlLock } = useControlsStore();

    const analyticsDispatcher = getRootDispatcher()
        .withExtra({
            partner: task.partner,
        })
        .withIdentities({
            ConferenceId: task.conferenceId,
            AsurionCallId: task.asurionCallId,
            AgentCallId: task.agentCallId,
            CallSid: task.agentCallId,
            CustomerCallId: task.customerCallId,
            TaskSid: task.id,
            SessionId: task.sessionId,
        });

    const [loading, handleTransfer] = useAsyncLoading(
        withControlsLock(async () => {
            await redirect();

            const vdnFriendlyName = transferVdn ? transferVdn.name : transferTfn;
            await task.updateCaseDetails({ transferToClaims: transferVdn?.group?.includes("Claims") ?? false });
            void analyticsDispatcher.dispatchBusinessEvent("TransferInitiated", {
                transferType: "REDIRECT",
                destination: transferVdn ? transferVdn : transferTfn,
                destinationFriendlyName: vdnFriendlyName ? vdnFriendlyName : null,
                destinationType: transferVdn ? "VDN" : "TFN",
            });
        }),
    );

    const redirect = async () => {
        if (transferVdn) {
            if (transferVdn.isInternal) await agentSdk.redirectCallToSIP(task, transferVdn.number);
            else await agentSdk.redirectCallToTFN(task, transferVdn.number);
            return;
        }

        if (!transferTfn) {
            throw new Error("No TFN provided");
        }

        const internalNumber = isInternalTfn(transferTfn, contacts);

        if (internalNumber) {
            await agentSdk.redirectCallToSIP(task, transferTfn);
            return;
        }

        await agentSdk.redirectCallToTFN(task, transferTfn);
    };

    const TransferBtn = withPolymorphicClickAnalytics(Button, "Click");

    return (
        <TransferBtn
            analytics={{
                actionId: "TransferCall",
                eventData: {
                    transferTfn,
                },
            }}
            color="var(--mantine-color-asurion-4)"
            data-testid="call-controls-transfer-complete"
            disabled={(!transferTfn && !transferVdn) || loading || controlLock}
            loading={loading}
            onClick={() => void handleTransfer()}
            variant="outline"
        >
            Transfer
        </TransferBtn>
    );
}
