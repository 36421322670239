import { createBrowserRouter } from "react-router";
import { AuthenticationProvider } from "../auth";
import Admin from "../pages/Admin";
import Home from "../pages/Home";
import { LoggedOut } from "../pages/LoggedOut";
import PerformanceMetrics from "../pages/PerformanceMetrics";
import { AdminRoutes } from "./AdminRoutes";
import { PerformanceMetricsRoutes } from "./PerformanceMetricsRoutes";

export const router = createBrowserRouter([
    {
        path: "/admin",
        element: (
            <AuthenticationProvider>
                <AdminRoutes />
            </AuthenticationProvider>
        ),
        children: [
            {
                path: "/admin",
                element: <Admin />,
            },
        ],
    },
    {
        path: "/logged-out",
        element: <LoggedOut />,
    },
    {
        path: "/",
        element: (
            <AuthenticationProvider>
                <Home />
            </AuthenticationProvider>
        ),
    },
    {
        path: "/full-offer-checklist",
        element: (
            <AuthenticationProvider>
                <PerformanceMetricsRoutes />
            </AuthenticationProvider>
        ),
        children: [
            {
                path: "/full-offer-checklist",
                element: <PerformanceMetrics />,
            },
        ],
    },
]);
