import { Menu, Text } from "@mantine/core";
import { forwardRef } from "react";
import type { MenuItemProps } from "./types";

export const ToolboxMenuItem = forwardRef<HTMLDivElement, MenuItemProps>(function ToolboxMenuItem(
    { id, item, focusedMenuItem, onClick }: MenuItemProps,
    ref,
) {
    return (
        <Menu.Item
            leftSection={item.icon}
            rightSection={
                <Text size="sm" c="gray.6">
                    {item.quickActionLabel ?? " "}
                </Text>
            }
            key={item.label}
            disabled={item.disabled}
            id={`toolbox-menu-item-${item.quickActionLabel}`}
            autoFocus={focusedMenuItem === id}
            onClick={() => {
                onClick(item);
            }}
            style={{ pointerEvents: "unset" }}
            {...item.additionalProps}
        >
            <Text size="sm" ref={ref}>
                {item.label}
            </Text>
        </Menu.Item>
    );
});
