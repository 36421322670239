import { Button, Flex, Table, Text } from "@mantine/core";
import { useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { CaretLeft, CaretRight } from "../common-ui";
import { useSetupSsoToken } from "../auth";
import { withPolymorphicClickAnalytics } from "../analytics";
import { AsurionLogo } from "../common-ui/assets/image/AsurionLogo";

export default function PerformanceMetrics(): React.JSX.Element {
    useSetupSsoToken();
    const data = [
        {
            empId: "300900",
            expertName: "John Doe",
            callsWithChecklist: 20,
            enrollments: 10,
            avgGps: 4.5,
            avgBenefits: 3.5,
            callsWithTransition: 15,
            callsWithPrice: 10,
            callsWithAskForSale: 5,
            callsWithBenefitRRR: 10,
            callsWithBenefitFutureDevices: 5,
            callsWithBenefitNonVerizonDevices: 3,
        },
        {
            empId: "300945",
            expertName: "Someone else",
            callsWithChecklist: 20,
            enrollments: 10,
            avgGps: 4.5,
            avgBenefits: 3.5,
            callsWithTransition: 15,
            callsWithPrice: 10,
            callsWithAskForSale: 5,
            callsWithBenefitRRR: 10,
            callsWithBenefitFutureDevices: 5,
            callsWithBenefitNonVerizonDevices: 3,
        },
    ];
    const headers = [
        "Expert Name",
        "Calls with checklist",
        "Enrollments",
        "Avg # GPS verbatims used",
        "Avg # benefits used",
        "Calls with transition verbatim",
        "Calls with price verbatim",
        "Calls with ask for sale verbatim",
        "Calls with benefit: RRR",
        "Calls with benefit: future devices",
        "Calls with benefit: Non-Verizon devices",
    ];

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("America/Chicago");
    const now = dayjs();
    const [selectedDate, setSelectedDate] = useState<Date | undefined>(now.toDate());
    const formattedDate = dayjs(selectedDate).format("ddd, MMM D");
    const isToday = dayjs(selectedDate).isSame(now, "day");
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "Click");

    const goToPreviousDay = () => {
        setSelectedDate((prevDate) => dayjs(prevDate).subtract(1, "day").toDate());
    };

    const goToNextDay = () => {
        if (!isToday) {
            setSelectedDate((prevDate) => dayjs(prevDate).add(1, "day").toDate());
        }
    };
    return (
        <>
            <Flex top="0px" left="0px" right="0px">
                <Flex h="1.8rem" ml="1.5rem" mt="0.85rem" mb="0.85rem">
                    <AsurionLogo />
                </Flex>
            </Flex>
            <Flex ml="4rem" mt="1.5rem">
                <Flex direction="column">
                    <Text size="1.25rem" fw={700}>
                        Full offer checklist performance
                    </Text>
                    <Flex align="center" pt="1rem">
                        <AnalyticsButton
                            analytics={{
                                actionId: "MetricsPreviousDay",
                            }}
                            onClick={goToPreviousDay}
                            variant="transparent"
                            c="dark.2"
                            p="5px"
                        >
                            <CaretLeft width={8} height={13} />
                        </AnalyticsButton>

                        <Text size="1rem" pl="1.5rem" pr="1.5rem">
                            {formattedDate}
                        </Text>

                        <AnalyticsButton
                            analytics={{
                                actionId: "MetricsNextDay",
                            }}
                            onClick={goToNextDay}
                            variant="transparent"
                            c="dark.2"
                            p="5px"
                            disabled={isToday}
                        >
                            <CaretRight width={8} height={13} />
                        </AnalyticsButton>
                    </Flex>

                    <Flex h="100%" mt="1rem" mr="3rem">
                        <Table
                            striped
                            highlightOnHover
                            withTableBorder
                            withColumnBorders
                            highlightOnHoverColor="asurion.5"
                        >
                            <Table.Thead>
                                <Table.Tr>
                                    {headers.map((header) => (
                                        <Table.Th key={header} ta="left">
                                            <Text size="0.875rem" fw={600}>
                                                {header}
                                            </Text>
                                        </Table.Th>
                                    ))}
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {data.map((metric) => (
                                    <Table.Tr key={metric.empId}>
                                        <Table.Td>{metric.expertName}</Table.Td>
                                        <Table.Td>{metric.callsWithChecklist}</Table.Td>
                                        <Table.Td>{metric.enrollments}</Table.Td>
                                        <Table.Td>{metric.avgGps}</Table.Td>
                                        <Table.Td>{metric.avgBenefits}</Table.Td>
                                        <Table.Td>{metric.callsWithTransition}</Table.Td>
                                        <Table.Td>{metric.callsWithPrice}</Table.Td>
                                        <Table.Td>{metric.callsWithAskForSale}</Table.Td>
                                        <Table.Td>{metric.callsWithBenefitRRR}</Table.Td>
                                        <Table.Td>{metric.callsWithBenefitFutureDevices}</Table.Td>
                                        <Table.Td>{metric.callsWithBenefitNonVerizonDevices}</Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}
