import { createContext, useCallback, useEffect, useState } from "react";
import { useLocalStorage } from "@mantine/hooks";
import { GlobalLoader } from "@expert/common-ui";
import { type CreateSdkParams } from "../../utils/createAgentSdk";
import AgentSdkContainer from "../../components/sdk/AgentSdkContainer";
import KonamiCode from "../../components/KonamiCode";
import { GlobalError } from "../../common-ui";
import { type ServiceProvider } from "../../shared-types/serviceProvider";
import type { AgentSdk } from "./agentSdk";
import { type AgentSdkLoaderProps, useAgentSdkLoader } from "./hooks/useAgentSdkLoader";
import { EvlDisconnectedErrorPage } from "./voice/EvlDisconnectedErrorPage";

export const AgentSdkContext = createContext<AgentSdk | undefined>(undefined);

export type AgentSdkProviderCreator = (params: CreateSdkParams) => Promise<AgentSdk>;

interface AgentSdkProviderProps {
    token: string | null;
    employeeNum: string | null;
    hdxEnabled: boolean;
    useSmartEVL: boolean;
    userFriendlyName?: string;
    children?: React.ReactNode;
    serviceProviders: ServiceProvider[];
}

type InnerProviderProps = AgentSdkProviderProps & Pick<AgentSdkLoaderProps, "createContainer">;

/** Inner provider so that containers can be rendered before SDK loading. */
function InnerProvider({
    token,
    employeeNum,
    hdxEnabled,
    useSmartEVL,
    children,
    userFriendlyName,
    serviceProviders,
    createContainer,
}: InnerProviderProps) {
    const { sdk, loadingMessage, errorMessage, failureType } = useAgentSdkLoader({
        token,
        employeeNum,
        hdxEnabled,
        useSmartEVL,
        userFriendlyName,
        serviceProviders,
        createContainer,
    });

    switch (failureType) {
        case "none":
            break;
        case "evl-failed":
            return <EvlDisconnectedErrorPage />;
        case "general":
            return <GlobalError title="Failed to create Agent SDK" msg={errorMessage} />;
    }

    if (loadingMessage) {
        return <GlobalLoader msg={loadingMessage} />;
    }

    if (!sdk) {
        throw new Error("Failed to initialize AgentSDK.");
    }

    return <AgentSdkContext.Provider value={sdk}>{children}</AgentSdkContext.Provider>;
}

export function AgentSdkProvider(props: AgentSdkProviderProps) {
    const createContainer = useCallback((name: string) => {
        const { promise, resolve } = Promise.withResolvers<HTMLElement>();
        setAgentSdkContainerResolversByName((prevAgentSdkContainerResolversByName) =>
            new Map(prevAgentSdkContainerResolversByName).set(name, resolve),
        );
        return promise;
    }, []);

    const [agentSdkContainerResolversByName, setAgentSdkContainerResolversByName] = useState(
        new Map() as ReadonlyMap<string, (value: HTMLElement) => void>,
    );

    const [showAgentSdkContainers, setShowAgentSdkContainers] = useLocalStorage({
        key: "show-agent-sdk-containers",
        defaultValue: false,
    });

    useEffect(() => {
        if (!showAgentSdkContainers) return;
        if (import.meta.env.MODE === "production") setShowAgentSdkContainers(false);
    }, [setShowAgentSdkContainers, showAgentSdkContainers]);

    return (
        <>
            {Array.from(agentSdkContainerResolversByName, ([name, resolve]) => (
                <AgentSdkContainer
                    key={name}
                    name={name}
                    opened={showAgentSdkContainers}
                    onClose={() => setShowAgentSdkContainers(false)}
                    ref={(element) => element && resolve(element)}
                />
            ))}
            <KonamiCode callback={() => setShowAgentSdkContainers(true)} />
            <InnerProvider {...props} createContainer={createContainer} />
        </>
    );
}
