import type { SVGProps } from "react";

export function CaretRight({ height = 8, width = 12, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" {...rest}>
            <path
                fill={fill ?? "var(--mantine-color-dark-2)"}
                fillRule="evenodd"
                d="M7.91406 6.50008L2.20696 12.2072L0.792742 10.793L5.08564 6.50008L0.792742 2.20718L2.20696 0.792969L7.91406 6.50008Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
