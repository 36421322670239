import { Autocomplete } from "@mantine/core";
import { parsePhoneNumber } from "awesome-phonenumber";
import type { ClipboardEvent } from "react";
import { useEffect, useState } from "react";
import IMaskInput from "react-imask/input";
import { getLogger } from "@expert/logging";
import {
    type Contact,
    type ContactGroup,
    type InputOption,
    type MaxLengthState,
    MDN_MASK,
    NO_MASK,
    PHONE_MAX_LENGTH,
    TFN_LENGTH,
    US,
    VDN_MASK,
    VDN_MAX_LENGTH,
} from "../../../shared-types";
import { useContactsStateStore } from "../vdnContacts.store";
import { CloseX } from "../../../common-ui";
import { extractNumbers, groupContacts, tryGetByVdnName, tryGetByVdnNumber } from "../../../shared-utils";
import classes from "./PhoneNumberInput.module.css";

const logger = getLogger({ module: "PhoneNumberInput" });

interface PhoneNumberInputProps {
    transferTfn: string | undefined;
    setTransferTfn: (tfn: string | undefined) => void;
    transferVdn: Contact | undefined;
    setTransferVdn: (vdn: Contact | undefined) => void;
}
export function PhoneNumberInput({
    transferTfn,
    setTransferTfn,
    transferVdn,
    setTransferVdn,
}: PhoneNumberInputProps): JSX.Element {
    const { contacts } = useContactsStateStore();

    const [displayValue, setDisplayValue] = useState<string>("");
    const [validTransferInput, setValidTransferInput] = useState<boolean>(false);

    const [quickTransferContacts, setQuickTransferContacts] = useState<ContactGroup[]>(groupContacts(contacts));

    const [enforceMaxLength, setEnforceMaxLength] = useState<MaxLengthState>({ enforce: true, length: TFN_LENGTH });

    // Switch that alternates between all 3 inputs that require different masks
    const [inputMask, setInputMask] = useState<string>(NO_MASK);
    const [inputType, setInputType] = useState<InputOption>();

    useEffect(() => {
        try {
            setQuickTransferContacts(groupContacts(contacts));
        } catch (error: unknown) {
            logger.error("Error setting contacts:", error);
        }
    }, [contacts]);

    useEffect(() => {
        switch (inputType) {
            case "PhoneNumber":
                setInputMask(MDN_MASK);
                setEnforceMaxLength({ enforce: true, length: PHONE_MAX_LENGTH });
                break;
            case "Extension":
                setInputMask(VDN_MASK);
                setEnforceMaxLength({ enforce: true, length: VDN_MAX_LENGTH });
                break;
            default:
                setInputMask(NO_MASK);
                setEnforceMaxLength({ enforce: false });
                break;
        }
    }, [inputType]);

    useEffect(() => {
        if (!validTransferInput) {
            // Make sure vdn & tfn are null until a valid entry
            if (transferVdn) {
                setTransferVdn(undefined);
            }
            if (transferTfn) {
                setTransferTfn(undefined);
            }
        }
        // eslint-disable-next-line
    }, [validTransferInput, displayValue]);

    function isTfn(value: string): boolean {
        const { valid } = parsePhoneNumber(`${US}${value}`);
        return valid;
    }

    function isVdnNumber(value: string): boolean {
        return !!tryGetByVdnNumber(value, contacts);
    }

    function isVdnName(value: string): boolean {
        return !!tryGetByVdnName(value, contacts);
    }

    function handleChange(value: string): void {
        if (!value) {
            setValidTransferInput(false);
            setDisplayValue(value);
            return;
        }

        setInputType("None");

        // Check for any non-phone number characters
        const isNotPhoneNumber = /[^0-9()+\- .]/.test(value);

        // Handle user entering a name (VDN)
        if (isNotPhoneNumber) {
            if (isVdnName(value)) {
                setValidTransferInput(true);
                setTransferVdn(tryGetByVdnName(value, contacts) ?? undefined);
            } else {
                setValidTransferInput(false);
            }
            setDisplayValue(value);
            return;
        }

        const digitsOnly = extractNumbers(value);

        if (isVdnNumber(digitsOnly)) {
            // Handle user entering a 7-digit number (VDN)
            setValidTransferInput(true);
            setTransferVdn(tryGetByVdnNumber(digitsOnly, contacts) ?? undefined);
            setInputType("Extension");
        } else if (isTfn(digitsOnly)) {
            // Handle user entering a 10-digit number (TFN)
            setValidTransferInput(true);
            setTransferTfn(digitsOnly);
            setInputType("PhoneNumber");
        } else {
            setValidTransferInput(false);
        }
        setDisplayValue(digitsOnly);
    }
    function handleSubmit(_: string): void {
        setDisplayValue("");
        setValidTransferInput(false);
    }

    function handlePaste(event: ClipboardEvent<HTMLInputElement>): void {
        event.preventDefault();

        const pastedData: string = event.clipboardData.getData("text");

        handleChange(pastedData);
    }

    const clearInput = () => handleChange("");

    const label = "Enter a phone number or extension";
    return (
        <Autocomplete
            renderRoot={({ ref, ...props }) => (
                <IMaskInput
                    mask={[
                        {
                            mask: inputMask,
                        },
                        {
                            mask: /^.*$/,
                        },
                    ]}
                    inputRef={ref as React.Ref<HTMLInputElement>}
                    maxLength={enforceMaxLength.length}
                    {...props}
                />
            )}
            value={displayValue}
            data={quickTransferContacts}
            type="tel"
            classNames={{
                input: classes.autocompleteInput,
                option: classes.autocompleteOption,
                section: classes.autocompleteSection,
            }}
            size="md"
            maw="30rem"
            mb="xs"
            autoFocus
            onChange={handleChange}
            onOptionSubmit={handleSubmit}
            onPaste={handlePaste}
            rightSection={displayValue.length ? <CloseX data-testid="clear-input" /> : null}
            rightSectionPointerEvents="auto"
            rightSectionProps={{ onClick: clearInput, title: "Clear", className: classes.clearButton }}
            data-testid="autocomplete"
            label={label}
            styles={{ label: { fontSize: "var(--mantine-font-size-sm)" } }}
        />
    );
}
