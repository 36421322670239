import { ActionIcon, Container, Text } from "@mantine/core";
import Player from "react-lottie-player/dist/LottiePlayerLight";
import { useEffect, useState } from "react";
import { getLogger } from "@expert/logging";
import { CloseX } from "../../common-ui";
import { BaseNotification } from "../BaseNotification/BaseNotification";
import { type CelebrationNotificationType, reactions } from "../models";
import { useAnalytics } from "../../analytics";

const logger = getLogger({
    module: "SaleMadeNotification",
    supportTeam: "sales",
});

export function CelebrationNotification({
    reactionType,
    sender,
    onClose,
}: CelebrationNotificationType["props"] & { onClose: () => void }) {
    const { dispatcher } = useAnalytics();
    const chosenReaction = reactions.find((r) => r.id === reactionType);
    const [isClosing, setIsClosing] = useState(false);
    setTimeout(() => setIsClosing(true), 5000);
    useEffect(() => {
        void dispatcher.dispatchBusinessEvent("CelebrationNotificationReceived", { reactionType, sender });
        logger.info({ reactionType, sender }, "CelebrationNotificationReceived");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BaseNotification onClose={onClose} isClosing={isClosing} side="right" width="21rem">
            {chosenReaction && <Text>{chosenReaction.value}</Text>}
            <Text c="dark.1" fw={700} size="sm">
                {sender} said congrats on the sale!
            </Text>
            <ActionIcon onClick={() => setIsClosing(true)} ml="0" mr={0} bg="transparent">
                <CloseX fill="var(--mantine-color-primary-1)" />
            </ActionIcon>
            {chosenReaction && (
                <Container style={{ pointerEvents: "none" }} pos="absolute">
                    <Player animationData={chosenReaction.animationData} play speed={1} loop />
                </Container>
            )}
        </BaseNotification>
    );
}
