import { Flex } from "@mantine/core";
import BotListeningLoader from "./bot-listening-loader/BotListeningLoader";
import BotTypingLoader from "./bot-typing-loader/BotTypingLoader";

const players = {
    botTyping: BotTypingLoader,
    botListening: BotListeningLoader,

    botIdle: () => <></>,
} satisfies Record<"botTyping" | "botListening" | "botIdle", () => JSX.Element>;

interface BubbleLoaderProps {
    className?: string;
    isLoading: boolean;
    isBotListening: boolean;
}

function BubbleLoader({ className, isLoading, isBotListening }: BubbleLoaderProps) {
    const idleAnimation = isBotListening ? "botListening" : "botIdle";
    const botAnimation = isLoading ? "botTyping" : idleAnimation;
    const BotAnimationComponent = players[botAnimation];
    return (
        <Flex align="center" className={className}>
            <BotAnimationComponent />
        </Flex>
    );
}
export default BubbleLoader;
