import React, { useMemo } from "react";
import {
    FeaturesProvider,
    type ContextProps,
    useFlags,
    type WorkspaceFeatures,
    type WorkspaceContextType,
    workspaceFeatures,
} from "@expert/features";
import { type Logger } from "@expert/logging";
import { usePartner } from "../sdk";
import { env } from "../../config";

interface SolveFeatureFlagProps {
    children: React.ReactNode;
    employeeId: string | null;
    logger: Logger;
}

export function SolveFeatureFlags({ children, employeeId, logger }: SolveFeatureFlagProps) {
    const partner = usePartner();

    const context = useMemo(() => {
        return {
            appName: "expert-workspace",
            employeeId,
            environment: env.mode,
            channel: env.channel,
            partner,
            source: "expert-ui",
        } as ContextProps;
    }, [employeeId, partner]);

    const features = useFlags<WorkspaceContextType>({
        analyticGroup: "Workspace",
        context,
        features: workspaceFeatures,
        logger,
    }) as WorkspaceFeatures;

    return <FeaturesProvider features={features}>{children}</FeaturesProvider>;
}
