import { Flex } from "@mantine/core";
import classes from "./TimelineItem.module.css";

export function TimelineItemWrapper({
    isFromAi,
    children,
}: {
    isFromAi: boolean;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <Flex
            align={isFromAi ? "flex-start" : "flex-end"}
            className={classes.timelineItemWrapper}
            justify={isFromAi ? "flex-start" : "flex-end"}
        >
            {children}
        </Flex>
    );
}
