import { useRecordedLegalDisclosure } from "@expert/contentful/src/hooks";
import { Button, type ComboboxItem, Flex, Group, Progress, Select, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { useAnalytics } from "../../../analytics";
import { type AudioType, isVoiceTask, useAgentSdk, useMostRecentTask, useSession, type VoiceTask } from "../../../sdk";
import { type EligibleProduct } from "../../home-product";
import { MessageBubbleIcon, PlayIcon, QuotationMarkIcon, SpeakerIcon } from "../../home-product/components/Icons";
import { AudioPlayingAnimation } from "../../home-product/components/Icons/AudioPlaying";
import { DisclosureModal } from "../components/DisclosureModal";
import { EnrollmentCard } from "../components/EnrollmentCard";
import { useDisclosureStepStore } from "../store/DisclosureStepStore";
import classes from "./RecordedLegalDisclosures.module.css";
import { EnrollSection } from "../../home-product/components/StepSpecific/EnrollSection";

export function RecordedLegalDisclosures({
    product,
    disclosureChecked,
}: {
    product: EligibleProduct;
    disclosureChecked: boolean;
}): JSX.Element {
    const mostRecentTask = useMostRecentTask();
    const agentSdk = useAgentSdk();
    const disclosureEndTime = useDisclosureStepStore((state) => state.recordedDisclosureEndTime);
    const setDisclosureEndTime = useDisclosureStepStore((state) => state.setRecordedDisclosureEndTime);
    const [disclosureLength, setDisclosureLength] = useState(0);
    const [isLoadingDisclosure, setIsLoadingDisclosure] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const session = useSession();
    const { dispatcher } = useAnalytics();
    const task = mostRecentTask as VoiceTask;

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }

    const { totalSeconds, restart, isRunning, pause } = useTimer({
        expiryTimestamp: disclosureEndTime
            ? new Date(disclosureEndTime)
            : new Date(Date.now() + 1000 * disclosureLength),
        autoStart: !!disclosureEndTime,
    });

    const languageStateItems: { value: AudioType; label: string }[] = [
        { value: "PreRecordedDisclosures", label: "English" },
        { value: "PreRecordedDisclosuresSpanish", label: "Spanish" },
    ] as const;

    const [languageState, setLanguageState] = useState<ComboboxItem>(
        mostRecentTask.locale === "es-mx" ? languageStateItems[1] : languageStateItems[0],
    );

    const url = useRecordedLegalDisclosure(product.productSku, languageState.label === "English" ? "en-US" : "es-US");

    const playLegalDisclosure = async () => {
        setIsLoadingDisclosure(true);
        void dispatcher.dispatchBusinessEvent("PlayLegalDisclosure_Clicked", { selectedLanguage: languageState.label });
        try {
            await agentSdk.playAudioInConference(task, languageState.value as unknown as AudioType, task.partner);
            const endTime = new Date(Date.now() + 1000 * disclosureLength);
            restart(endTime, true);
            setDisclosureEndTime(endTime);
        } catch (e) {
            /* empty */
        }
        setIsLoadingDisclosure(false);
    };

    useEffect(() => {
        if (task.status !== "assigned" || !task.customerCallId) {
            pause();
        }
    }, [pause, task.customerCallId, task.status]);

    useEffect(() => {
        if (!url) {
            return;
        }

        const audio = new Audio(url);

        audio.addEventListener("loadedmetadata", () => {
            setDisclosureLength(Math.floor(audio.duration));
        });
    }, [url]);

    return (
        <>
            <Flex direction="column" className={classes.recordedDisclosureWrapper}>
                <Flex direction="column" className={disclosureEndTime ? classes.topAnimation : undefined}>
                    <Group
                        grow
                        preventGrowOverflow={false}
                        wrap="nowrap"
                        fz="1rem"
                        fw="bold"
                        className={classes.hideTop}
                    >
                        Play the disclosures
                        {/* Only show language options for spanish-speaking customers */}
                        {mostRecentTask.locale === "es-mx" && (
                            <Select
                                allowDeselect={false}
                                comboboxProps={{ withinPortal: false }}
                                data={languageStateItems}
                                leftSection={<SpeakerIcon />}
                                onChange={(_value, option) => setLanguageState(option)}
                                style={{ maxWidth: "130px" }}
                                value={languageState.value}
                                withCheckIcon={false}
                            />
                        )}
                    </Group>
                    <Text mt="24px" size="14px">
                        Tell the customer:
                    </Text>
                    <Flex justify="space-between" mt="10px">
                        <QuotationMarkIcon />
                        <Text maw="89%" size="sm">
                            I’m going to play you a short recording with important plan disclosures. Let’s stay on the
                            line together and then I’ll finish your enrollment.
                        </Text>
                    </Flex>
                </Flex>
                <Flex direction="column" className={classes.offerBox} bg="dark.4" mt="1.5rem">
                    <Flex direction="column" p="1.5rem" gap="4px">
                        <>
                            <Flex className={classes.timeMarkers} w="100%" c="dark.1" ff="monospace" lh="140%">
                                <Text size="xs">
                                    {disclosureEndTime
                                        ? disclosureLength - totalSeconds >= 10
                                            ? `0:${disclosureLength - totalSeconds}`
                                            : `0:0${disclosureLength - totalSeconds}`
                                        : "0:00"}
                                </Text>
                                <Text size="xs">0:{disclosureLength}</Text>
                            </Flex>
                            <Progress
                                size="sm"
                                className={classes.progressBar}
                                value={
                                    isRunning || disclosureEndTime
                                        ? Math.floor(((disclosureLength - totalSeconds) / disclosureLength) * 100)
                                        : 0
                                }
                                color="#38D9A9"
                                transitionDuration={2000}
                            />
                        </>
                        <Flex style={{ alignSelf: "end" }} w="58%" mt="1rem">
                            <Flex>
                                <Button
                                    className={classes.playbackButton}
                                    color="dark.0"
                                    disabled={
                                        session.currentTask?.status !== "assigned" || isRunning || !task.customerCallId
                                    }
                                    loading={isLoadingDisclosure}
                                    p="10px"
                                    radius="xl"
                                    size="xl"
                                    w="3.75rem"
                                    variant="outline"
                                    onClick={() => void playLegalDisclosure()}
                                >
                                    {isRunning ? <AudioPlayingAnimation /> : <PlayIcon />}
                                </Button>
                            </Flex>
                            <Button
                                onClick={() => {
                                    open();
                                    void dispatcher.dispatchBusinessEvent("LegalDisclosureText_Clicked");
                                }}
                                ml="auto"
                                radius="xl"
                                size="lg"
                                p="10px"
                                w="4rem"
                                variant="transparent"
                            >
                                <MessageBubbleIcon />
                            </Button>
                        </Flex>
                    </Flex>
                    {mostRecentTask.status === "assigned" &&
                    task.customerCallId &&
                    disclosureEndTime &&
                    new Date().getTime() >= disclosureEndTime ? (
                        <EnrollmentCard product={product} />
                    ) : null}
                </Flex>
                <DisclosureModal
                    opened={opened}
                    close={close}
                    productSku={product.productSku}
                    language={languageState.label === "English" ? "en-US" : "es-US"}
                />
            </Flex>
            <EnrollSection
                product={product}
                isEnrollButtonEnabled={false}
                disclosureChecked={disclosureChecked}
                animateAway={!!disclosureEndTime}
            />
        </>
    );
}
