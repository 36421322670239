import { Flex, Group, Text, type TextProps } from "@mantine/core";
import { MarkdownViewer } from "@expert/solve-tools";
import { NotesStarIcon } from "../../../assets/NotesStarIcon";
import type { SessionSummaryMetadata } from "../../../shared-types";

const headings = ["Call reason", "Action taken", "Call conclusion", "Follow ups", "Additional info"];
function HeadingText(props: TextProps) {
    return <Text size="sm" fw={700} {...props} pt="sm" />;
}
export function generateSessionSummaryItem(metadata: SessionSummaryMetadata) {
    // regex to find occurrences of headings & inject heading4 markdown for styling purposes
    const headingRegex = new RegExp(headings.join("|"), "gi");
    const summaryWithMarkdown = metadata.summary.replace(headingRegex, "#### $&");
    const mt = metadata.summary.includes(headings[0]) ? "-0.5rem" : "0";
    return (
        <Flex direction="column" mt={mt}>
            <MarkdownViewer content={summaryWithMarkdown} options={{ overrides: { h4: HeadingText } }} />
        </Flex>
    );
}

export function SessionSummaryHeader({
    metadata,
    isLastProactiveMessage = true,
}: {
    metadata: SessionSummaryMetadata;
    isLastProactiveMessage: boolean;
}) {
    const dateString = metadata.timestamp
        ? new Date(metadata.timestamp).toLocaleDateString("en-us", {
              month: "short",
              day: "numeric",
          })
        : undefined;
    return (
        <Group
            gap="xs"
            p="0.5rem 1rem"
            bg={isLastProactiveMessage ? "linear-gradient(to right, #8223d2, #5034cf)" : "var(--mantine-color-dark-4)"}
            styles={{ root: { borderTopLeftRadius: "1.25rem", borderTopRightRadius: "1.25rem" } }}
        >
            <NotesStarIcon />
            <Text size="sm">
                <strong>{dateString ? dateString + " " : metadata.header}</strong>
                {dateString && metadata.header.toLowerCase()}
            </Text>
        </Group>
    );
}
