import React, { useEffect, useRef, useState } from "react";
import Player from "react-lottie-player/dist/LottiePlayerLight";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { trackFullStoryEvent } from "@expert/monitoring";
import { type ExpertAssistMessage } from "../../shared-types";
import thumbsDownLottie from "./thumbsDownLottie.json";
import thumbsUpLottie from "./thumbsUpLottie.json";
import classes from "./FeedbackIcons.module.css";

interface FeedbackIconProps {
    onFeedbackClick: () => void;
    animationType: "up" | "down";
    message: ExpertAssistMessage | undefined;
}
function FeedbackIcons({ onFeedbackClick, animationType, message }: FeedbackIconProps) {
    const animationData = animationType === "up" ? thumbsUpLottie : thumbsDownLottie;
    const [isPlaying, setIsPlaying] = useState(false);
    const [segments, setSegments] = useState<[number, number]>([1, 80]);
    const playerRef = useRef(null);
    const { dispatcher } = useReactAnalytics();

    useEffect(() => {
        setIsPlaying(true);
        setTimeout(() => setIsPlaying(false), 1000);
    }, []);

    const handleMouseEnter = () => {
        setIsPlaying(true);
        setSegments([90, 160]);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000);
    };

    const handleClick = () => {
        setIsPlaying(true);
        setSegments([192, 292]);
        setIsPlaying(true);
        const text = message ? message.text.slice(0, 100) : "";
        const button = animationType === "up" ? "FeedbackThumbsUp" : "FeedbackThumbsDown";
        void dispatcher.dispatchAction("Click", "MessageFeedbackDetails", {
            button,
            messageId: message?.id,
            text,
            messageType: message?.type,
        });
        trackFullStoryEvent("Click_MessageFeedbackDetails", {
            button,
            messageId: message?.id,
            messageType: message?.type,
        });
        setTimeout(() => {
            setIsPlaying(false);
            onFeedbackClick();
        }, 1000);
    };

    return (
        <Player
            ref={playerRef}
            animationData={animationData}
            play={isPlaying}
            segments={segments}
            className={classes.player}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
        />
    );
}

export default FeedbackIcons;
