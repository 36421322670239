import "./UserInitial.css";

interface UserInitialsProps {
    name: string;
}

export function UserInitial({ name }: UserInitialsProps): JSX.Element {
    return (
        <div className="initial-icon">
            <div className="initial-text">{name.charAt(0).toUpperCase()}</div>
        </div>
    );
}
