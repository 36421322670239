import { customCleanEnv, url } from "envalid";

interface Env {
    channelShareDatabaseBaseUrl: string;
}

export const env = customCleanEnv(
    import.meta.env,
    {
        VITE_CHANNEL_SHARE_DATABASE_BASE_URL: url(),
    },
    (inputEnv): Env => ({
        channelShareDatabaseBaseUrl: inputEnv.VITE_CHANNEL_SHARE_DATABASE_BASE_URL,
    }),
);
