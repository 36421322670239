import type { TextProps } from "@mantine/core";
import { Text } from "@mantine/core";
import styles from "../../../common-ui/sharedStyles.module.css";
import { formatTime } from "../../../shared-utils";

type CallControlProps = TextProps & {
    totalSeconds: number;
    format?: string;
    appendUnit?: boolean;
};

/** Simple reuseable countdown text for the wrapping time */
export function WrappingCountdownText({
    totalSeconds,
    format,
    appendUnit,
    size,
    ...other
}: CallControlProps): JSX.Element {
    return (
        <Text className={styles.noWrapWhiteSpace} size={size ?? "sm"} span {...other}>
            {formatTime(totalSeconds, appendUnit, format)}
        </Text>
    );
}
