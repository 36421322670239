import Markdown, { type MarkdownToJSX } from "markdown-to-jsx";
import { Fragment } from "react";
import { baseOverride, hyperMediaOverride, listOverride } from "./overrides";

interface MarkdownViewerProps {
    content: string;
    options?: MarkdownToJSX.Options;
}

export function MarkdownViewer({ content, options }: MarkdownViewerProps): JSX.Element {
    return (
        <Markdown
            options={{
                wrapper: Fragment,
                forceBlock: true,
                overrides: {
                    ...hyperMediaOverride,
                    ...listOverride,
                    ...baseOverride,
                },
                ...options,
            }}
        >
            {content}
        </Markdown>
    );
}
