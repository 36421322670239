import { Container, Divider } from "@mantine/core";
import timelineClasses from "./TimelineItem.module.css";

export function Separator({ label }: { label?: string }) {
    return (
        <Container w="75%">
            <Divider
                classNames={{
                    root: timelineClasses.divider,
                    label: timelineClasses.label,
                }}
                label={label}
                labelPosition="center"
                mt="5rem"
                mb="5rem"
            />
        </Container>
    );
}
