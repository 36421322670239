import { MarkdownViewer } from "@expert/solve-tools";
import type { ExpertAssistMessage } from "../../shared-types";
import { generateSessionSummaryItem } from "../message-bubble/custom-messages/SessionSummary";
import { Separator } from "../timeline-item/Separator";

export function generateTimelineItemFromType(message: ExpertAssistMessage) {
    switch (message.type) {
        case "Connection":
        case "Expert":
        case "Default":
        case "QuickAction":
        case "Proactive":
        case "Search":
            return <MarkdownViewer content={message.text} />;
        case "SessionSummary": {
            const sessionSummary = message.metadata?.sessionSummary;
            return sessionSummary && generateSessionSummaryItem(sessionSummary);
        }
        case "Separator": {
            return <Separator label={message.text} />;
        }
    }
}
