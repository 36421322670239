import { Button, Group, Input } from "@mantine/core";
import { parsePhoneNumber } from "awesome-phonenumber";
import type { ClipboardEvent } from "react";
import { useState } from "react";
import { useTimeout } from "@mantine/hooks";
import { withPolymorphicClickAnalytics } from "../../../analytics";
import { MdnInput } from "../../../common-ui";
import { useAgentSdk } from "../../../sdk";
import { useAsyncLoading } from "../../../shared-utils";
import { isProd } from "../../constants";
import { randomZoomPhoneNumber } from "../../zoom";
import noCallControlsClasses from "./NoCallControls.module.css";

interface NoActiveCallProps {
    onAddCall: () => void;
    onCancel: () => void;
}

const NoCallControlsButton = withPolymorphicClickAnalytics(Button, "Click");

export function NewCallCallControl({ onAddCall, onCancel }: NoActiveCallProps) {
    const agentSdk = useAgentSdk();

    const [mdn, setMdn] = useState("");
    const [isMDNValid, setIsMDNValid] = useState(false);
    const [isEnteringNumber, setIsEnteringNumber] = useState(false);
    const [extension, setExtension] = useState<string>();
    const [pendingNewCall, setPendingNewCall] = useState(false);
    const { start } = useTimeout(() => setPendingNewCall(false), 10000);

    const [newCallLoading, handleNewCall] = useAsyncLoading(async () => {
        setPendingNewCall(true);
        await agentSdk.callCustomer(mdn, { extension });

        // Wait up to 10 seconds to prevent multiple clicks
        start();
    });

    function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
        if (isProd) return;
        // for non-prod only, intercept Zoom link pasting:
        // 1. set MDN to a random Zoom phone number
        // 2. encode the meeting ID into an extension to dial with delays (`w`) and confirms (`#`)
        const plainTextData = e.clipboardData.getData("text/plain");
        const match = /^https:\/\/asurion\.zoom\.us\/j\/(?<meetingId>\d+)/.exec(plainTextData);
        if (match?.groups === undefined) return;
        e.preventDefault();
        const { meetingId } = match.groups;
        const zoomPhoneNumber = randomZoomPhoneNumber();
        e.currentTarget.value = zoomPhoneNumber;
        handleInput(zoomPhoneNumber);
        // each `w` is 500ms (see https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callsenddigitsdigits)
        setExtension(`${"w".repeat(8)}${Array.from(meetingId).join("w")}ww#${"w".repeat(8)}#`);
    }

    function handleInput(inputMDN: string) {
        const { number, valid } = parsePhoneNumber(inputMDN);
        setMdn(number?.e164 ?? "");
        setIsMDNValid(valid);
        if (inputMDN) {
            setIsEnteringNumber(true);
        } else {
            setIsEnteringNumber(false);
        }
        setExtension(undefined);
    }

    function handleCancelAddCallClick() {
        setIsEnteringNumber(false);
        setIsMDNValid(false);
        setMdn("");
        setExtension(undefined);
        onCancel();
    }

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && !newCallLoading && isMDNValid) {
            await handleNewCall();
        }
    };

    return (
        <Group gap="xs">
            <Input.Wrapper
                classNames={{ description: noCallControlsClasses.description }}
                description={isEnteringNumber ? "Enter a number" : null}
            >
                <MdnInput
                    onClick={onAddCall}
                    onPaste={handlePaste}
                    onInput={(e) => {
                        handleInput(e.currentTarget.value);
                    }}
                    autoFocus
                    onKeyDown={(e) => void handleKeyDown(e)}
                    variant="unstyled"
                    classNames={{ input: noCallControlsClasses.input }}
                    placeholder="Enter a number"
                />
            </Input.Wrapper>
            <Group gap="sm" justify="flex-end" flex={1}>
                <NoCallControlsButton
                    analytics={{
                        actionId: "NoCallControls_CancelNewCallButton",
                    }}
                    color="dark.1"
                    onClick={handleCancelAddCallClick}
                    disabled={newCallLoading || pendingNewCall}
                    size="sm"
                    className={noCallControlsClasses.cancelButton}
                    variant="transparent"
                >
                    Cancel
                </NoCallControlsButton>
                <NoCallControlsButton
                    analytics={{
                        actionId: "NoCallControls_StartCallButton",
                    }}
                    color="primary.6"
                    disabled={!isMDNValid || newCallLoading || pendingNewCall}
                    loading={newCallLoading || pendingNewCall}
                    size="sm"
                    onClick={() => void handleNewCall()}
                    variant="filled"
                >
                    Call
                </NoCallControlsButton>
            </Group>
        </Group>
    );
}
