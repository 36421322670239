export interface FeedbackPayload {
    appName: string;
    caller: FeedbackCaller;
    callSid?: string;
    category: string;
    extraData?: Record<string, unknown>;
    sessionId: string;
    ssoAccessToken: string;
    text: string;
    partner: string;
}
export type PartialFeedbackPayload = Omit<FeedbackPayload, "category" | "text">;

export type FeedbackCaller =
    | "StandaloneHeader"
    | "StandaloneTimeline"
    | "PlatformHeader"
    | "PlatformTimeline"
    | "GuideCard";

export type FeedbackType = "NoTextFeedback" | "FreeTextFeedback";

// TODO: Move this to app config?
export const partnerFeedbackConfig = {
    asurion: "FreeTextFeedback",
    att: "FreeTextFeedback",
    mcafee: "FreeTextFeedback",
    verizon: "NoTextFeedback",
    default: "NoTextFeedback",
} as Record<string, FeedbackType>;

// Free Text Feedback
const FreeTextCategories = ["General feedback", "Something’s broken"] as const;
export type FreeTextCategory = (typeof FreeTextCategories)[number];
export const feedbackUIMappingFreeText = {
    default: {
        title: "We’re all ears!",
        subtitle: "Share issues, things you want, and any other feedback",
        placeholder: {
            "General feedback":
                "Example: I want to be able to text customers a picture of where to find a setting on their phone",
            "Something’s broken": "Example: I can’t type in the chat",
        } as Record<FreeTextCategory, string>,
        bottomText: "We’ll save your progress in case you need to come back.",
        categories: FreeTextCategories,
    },
    success: {
        title: "Thanks for sharing!",
        subtitle: "We read every response and appreciate all of it",
    },
};

// No Text Feedback
const NoTextCategories = [
    "Technical issues in workspace",
    "Scout/Expert Assist",
    "Sales or enrollment issues in workspace",
    "Device activation guide",
] as const;
export type NoTextCategory = (typeof NoTextCategories)[number];

const TechnicalIssues = ["Freezing", "Transfers", "Callbacks", "Audio", "Dropped calls", "Other"] as const;

const ExpertAssistIssues = [
    "Inaccurate responses",
    "Bot not responding",
    "Outdated responses",
    "Doesn’t recognize device",
    "Unhelpful suggestions",
] as const;

const SalesIssues = [
    "Enrollment failures",
    "Incorrect eligibility",
    "Devices aren’t being detected automatically",
    "Other",
] as const;

const DeviceActivationIssues = ["Wrong steps", "Device not supported", "Other"] as const;

const SERVICE_NOW_URL =
    "https://asurion.service-now.com/serviceportal?id=sc_cat_item&sys_id=de3805660f8706007889af58b1050ea9";

export const feedbackUIMappingNoText = {
    title: "What are you having trouble with?",
    subtitle: `For critical issues, submit a 
    <a href='${SERVICE_NOW_URL}' target='_blank'>Service Now</a> ticket—it’s the fastest way to get help!`,
    categories: NoTextCategories,
    subcategories: {
        "Technical issues in workspace": TechnicalIssues,
        "Scout/Expert Assist": ExpertAssistIssues,
        "Sales or enrollment issues in workspace": SalesIssues,
        "Device activation guide": DeviceActivationIssues,
    } as Record<NoTextCategory, readonly string[]>,
};

// UI mapping helpers
export const getTitle = (isSuccess: boolean, feedbackType: FeedbackType) => {
    return isSuccess
        ? feedbackUIMappingFreeText.success.title
        : feedbackType === "FreeTextFeedback"
          ? feedbackUIMappingFreeText.default.title
          : feedbackUIMappingNoText.title;
};
