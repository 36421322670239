import { getLogger } from "@expert/logging";
import { useEffect } from "react";
import { useAgentSdk, type TrainingTask } from "../../sdk";
import { SharedModal } from "./SharedModal";
import { DEFAULT_TRAINING_MODAL_TIMEOUT, playAudio, type TrainingStep } from "./utilities";
import { useTrainingStateStore } from "./training.store";

const logger = getLogger({ module: "TrainingModal" });

interface TrainingModalProps {
    task: TrainingTask;
}

export function TrainingModal({ task }: TrainingModalProps): JSX.Element {
    const { trainingStep, expirationTimestamp, setExpirationTimestamp } = useTrainingStateStore();
    const agentSdk = useAgentSdk();

    const isValidLink = (link?: string): link is string => {
        if (!link) {
            return false;
        }

        return URL.parse(link) !== null;
    };

    const allLinks = isValidLink(task.link) ? [task.link] : (task.attachments?.map(({ url }) => url) ?? []);
    const validLinks = new Set<string>(allLinks.filter(isValidLink));

    if (!task.startTimestamp) throw new Error("Should not render training task without startTimestamp");

    useEffect(() => {
        if (trainingStep === "starting") {
            setExpirationTimestamp((task.startTimestamp ?? Date.now()) + DEFAULT_TRAINING_MODAL_TIMEOUT);
            playAudio("/assets/audio/training-start.mp3");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const badgeTitles: Record<TrainingStep, string> = {
        "high-volume-cancellation": "Training Postponed",
        "time-limit-met-cancellation": "Training Postponed",
        "in-progress": "Training Started",
        starting: "New Training",
    };

    const showBadgeMinutesRemaining: Record<TrainingStep, boolean> = {
        "high-volume-cancellation": false,
        "time-limit-met-cancellation": false,
        "in-progress": true,
        starting: true,
    };

    const specialTitles: Record<TrainingStep, string | undefined> = {
        "high-volume-cancellation": "High call volume– you’ll be returned to available status",
        "time-limit-met-cancellation": "Time limit met– you’ll be returned to available status",
        "in-progress": undefined,
        starting: undefined,
    };

    const completeTask = () => {
        logger.trace({ task, trainingStep }, `Training session concluded while in ${trainingStep} step.`);
        playAudio("/assets/audio/training-end.mp3");
        void agentSdk.completeTask(task, "TrainingCompleted");
    };

    return (
        <SharedModal
            badgeTitle={badgeTitles[trainingStep]}
            showBadgeMinutesRemaining={showBadgeMinutesRemaining[trainingStep]}
            badgeMinutes={task.lengthMinutes}
            timerExpirationTimestamp={expirationTimestamp ?? task.startTimestamp + DEFAULT_TRAINING_MODAL_TIMEOUT}
            trainingName={specialTitles[trainingStep] ?? task.name}
            trainingNotes={task.notes}
            trainingStarted={trainingStep !== "starting"}
            completeTask={completeTask}
            includeSubmitTimer={trainingStep !== "in-progress"}
            trainingLinks={validLinks}
            task={task}
        />
    );
}
